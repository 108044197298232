var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabel-container"},[_c('Message',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageBok),expression:"messageBok"}],attrs:{"userList":_vm.userList},model:{value:(_vm.sendmessage),callback:function ($$v) {_vm.sendmessage=$$v},expression:"sendmessage"}}),_c('v-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.sendmessage),expression:"sendmessage"}],staticClass:"i-checkbox",staticStyle:{"margin-top":"0"},attrs:{"label":"เลือกทั้งหมด"},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}}),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.data,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"page":_vm.page,"show-select":_vm.showSelect,"header-props":{
      'sort-icon': 'mdi-chevron-up'
    },"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('h4',[_vm._v("ไม่มีข้อมูล")])]},proxy:true},{key:"no-results",fn:function(){return [_c('h4',[_vm._v("ไม่พบข้อมูลที่ค้นหา")])]},proxy:true},{key:"item.no",fn:function(ref){
    var itemValue = ref.item;
return [_c('div',[_vm._v(" "+_vm._s((_vm.page - 1) * _vm.options.itemsPerPage + _vm.data.findIndex(function (item) { return item[_vm.modelKey] === itemValue[_vm.modelKey]; }) + 1)+" ")])]}},_vm._l((_vm.editColumns),function(slot,i){return {key:("item." + (slot.value)),fn:function(ref){
    var item = ref.item;
    var header = ref.header;
return [_c(slot.componentName,_vm._b({key:i,tag:"component"},'component',Object.assign({}, item, {header: header}),false))]}}}),{key:"item.price",fn:function(ref){
    var item = ref.item;
return [(item.price != null)?_c('div',[_vm._v(_vm._s(item.price))]):_c('div',[_vm._v("0")])]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{ref:"skeleton",staticStyle:{"width":"100%"},attrs:{"boilerplate":_vm.boilerplate,"type":_vm.type,"tile":_vm.tile}})]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true},{key:"item.check",fn:function(ref){
    var item = ref.item;
return [(_vm.sendmessage)?_c('div',[_c('v-checkbox',{staticClass:"i-checkbox",staticStyle:{"margin-top":"0"},on:{"click":function($event){return _vm.select(item)}},model:{value:(item.select),callback:function ($$v) {_vm.$set(item, "select", $$v)},expression:"item.select"}})],1):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }