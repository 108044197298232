<template>
  <div>
    <i-header title="ลงทะเบียน AMED"><div></div></i-header>
    <Datatables
      :columns="columns"
      :url="`/syn/homeisolation/couponisolationimport?synHomeiId=${$route.params.id}`"
    />
  </div>
</template>

<script>
import moment from "moment";
import { columns } from "data/tables/regist-amed-detail-columns";
import Datatables from "components/datatables/index.vue";

export default {
  data: () => ({
    columns,
    name: "",
    statusSelected: "",
    url: `/`
  }),
  components: { Datatables },
  computed: {
    dateNow() {
      return moment(Date.now()).format("YYYY-MM-DD");
    }
  },
  watch: {},
  methods: {
    async handleClick({ name, selected, date }) {
      this.name = name;
      this.statusSelected = selected;
      //   console.log(name, selected, date);
      this.url = `/syn/homeisolation/getpatient?agencyId=${
        selected ? selected : ""
      }&name=${name}&createTime=${date ? date : ""}`;
    }
  }
};
</script>

<style scoped></style>
