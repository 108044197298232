<template>
  <v-app-bar app dense clipped-left style="background: #fff">
    <v-app-bar-nav-icon @click="$emit('toggle-drawer')"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            {{ user.first_name_eng }} {{ user.last_name_eng }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="handleLogout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["user"])
  },
  methods: {
    ...mapActions("auth", ["logout", "getUserProfile"]),
    handleBack() {
      const prevRoute = {
        agency: "home",
        donation: "agency"
      };
      this.$router.push({ name: prevRoute[this.$route.name] });
      // this.$router.back();
    },
    handleLogout() {
      this.logout();
      this.$router.push("/login");
    }
  },
  mounted() {
    this.getUserProfile();
  }
};
</script>

<style scoped>
.topbar-container {
  display: flex;
  height: 70px;
  padding: 0 24px;
  align-items: center;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.25);
}
.topbar-inner {
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: #333333;
}
</style>
