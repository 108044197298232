import {
  SET_IS_AUTH,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  RESET_AUTH
} from "./constant";

import { getUserService, loginService } from "services/auth.service";

export default {
  setIsAuth: ({ commit }) => {
    commit(SET_IS_AUTH);
  },
  getUserProfile: async ({ commit }) => {
    try {
      commit(GET_USER_PROFILE);
      const result = await getUserService();
      commit(GET_USER_PROFILE_SUCCESS, result);
    } catch (error) {
      commit(GET_USER_PROFILE_FAILURE, error.message);
    }
  },
  login: async ({ commit }, payload) => {
    try {
      const result = await loginService(payload);
      return result;
    } catch (error) {
      commit(GET_USER_PROFILE_FAILURE, error.message);
    }
  },
  logout: ({ commit }) => {
    commit(RESET_AUTH);
    localStorage.clear();
  }
};
