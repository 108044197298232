<template>
  <div>
    <div class="mt-3">
      <i-card>
        <i-header title="กำหนดการเข้าร่วมแคมเปญ"><div></div></i-header>
        <div>
          <v-row
            class="mt-3"
            align="center"
            justify="center"
            v-if="this.mode.mode == 'CREATE' || this.mode.mode == 'EDIT'"
            no-gutters
          >
            <v-col cols="12">
              <h4 class="font-weight-regular">
                เลือกห้าง
              </h4>
            </v-col>
            <v-col cols="12">
              <!-- <v-combobox
                v-model="name_department"
                :item-text="item_text_search_department"
                :items="department_list"
                placeholder="ค้นหาชื่อห้าง"
                @change="check_id(name_department)"
                return-object
                clear-icon
                hide-selected
                dense
                outlined
              >
              </v-combobox> -->
              <v-select
                :items="department_list"
                v-model="prop_campaing.departmentStoreId"
                item-text="departmentName"
                item-value="departmentStoreId"
                outlined
                placeholder="ค้นหาชื่อห้าง"
                dense
                :rules="rules_department"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <h4 class="font-weight-regular">
                เลือกแคมเปญ
              </h4>
            </v-col>
            <v-col cols="12">
              <!-- <v-combobox
                v-model="name_campaign"
                :item-text="item_text_search"
                :items="list_data_campaing"
                placeholder="ค้นหาชื่อแคมเปญ"
                @change="check_name_campaign(name_campaign)"
                return-object
                clear-icon
                hide-selected
                dense
                outlined
                clearable
              >
              </v-combobox> -->
              <v-select
                v-model="prop_campaing.campaign"
                item-text="campaignName"
                item-value="campaignId"
                :items="campaign_list_name"
                placeholder="แคมเปญ"
                clear-icon
                hide-selected
                dense
                outlined
                clearable
                :rules="rules_campaign"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <h4 class="font-weight-regular">
                ระบุแท็กที่ต้องการ
              </h4>
            </v-col>
            <v-col cols="12" class="py-3">
              <v-combobox
                v-model="prop_campaing.tags"
                :search-input.sync="search"
                :items="name_tag"
                hide-selected
                outlined
                clearable
                dense
                multiple
                persistent-hint
                small-chips
                placeholder="เพิ่มแท็กที่ต้องการ"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        สามารถเพิ่มได้ โดยการเพิ่มและกด
                        <kbd>enter</kbd> จะเป็นการสร้าง tag ใหม่
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>
import * as rules from "@/javascript/rules.js";
import axios from "clients/axios";
export default {
  props: {
    coupon_type: {
      type: String,
      require: true,
      default: ""
    },
    prop_campaing: {
      type: Object,
      require: true,
      default() {}
    },
    mode: {
      type: Object,
      require: true,
      default() {}
    },
    campaign_list: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      code: "",
      //rules
      code_coupon: rules.code_coupon,
      name_coupon: rules.name_coupon,
      description_coupon: rules.description_coupon,
      check_type_coupong: "",
      search_value_campaing: "",
      search_value_department: "",
      list_data_campaing: [],
      list_data_department: [],
      list_department: [],
      campaign_list_name: [],
      // name_department: this.department_list.departmentStoreId,
      name_campaign: null,
      show_campaign: null,
      list_campaign: "",
      name_tag: [],
      search: null,
      department_list: [
        {
          departmentName: "one chat Food",
          departmentStoreId: "5fb69c9133836a18f22b5789"
        }
      ],
      rules_campaign: [
        v => !!v || "* กรุณาระบุแคมเปญ",
        v => (v || "").split(" ")[0] != "" || "* ข้อความต้องไม่เริ่มหรือสิ้นสุดด้วยค่าว่าง"
      ],
      rules_department: [
        v => !!v || "* กรุณาเลือกห้าง",
        v => (v || "").split(" ")[0] != "" || "* ข้อความต้องไม่เริ่มหรือสิ้นสุดด้วยค่าว่าง"
      ]
      // tag_select: [
      //   v => !!v || "* กรุณาระบุแท็ก",
      //   v => (v || "").split(" ")[0] != "" || "* ข้อความต้องไม่เริ่มหรือสิ้นสุดด้วยค่าว่าง"
      // ],
    };
  },
  created() {
    // this.list_data_department = this.department_list;
    this.show_campaign = this.prop_campaing;

    this.check_type_coupong = this.$route.params.create_type;
    this.list_data_campaing = this.campaign_list;

    // this.getListDepartmentstore();
    this.getCampaign();
    this.getTagsActive();
  },
  watch: {
    campaign_list(val) {
      this.list_data_campaing = val;
    }
    // department_list(val) {
    //   this.list_data_department = val;
    // },
    // name_campaign(val) {
    //   let check_name = val.campaignName;
    //   if (check_name == undefined) {
    //     this.name_campaign = "";
    //   } else {
    //     const found = this.list_data_campaing.find(element => check_name == element.campaignName);
    //     console.log(found);
    //   }
    // }
  },
  methods: {
    uppercase() {
      this.code = this.code.toUpperCase();
    },
    item_text_search: item => item.campaignName,
    item_text_search_department: item => item.departmentStoreName,
    check_name_campaign(name_campaign) {
      this.prop_campaing.campaign = name_campaign.campaignId;
    },

    async getCampaign() {
      const res = await axios.get("/coupon/tags-campaign");
      this.campaign_list_name = res.data.data.listCampaign;
      // console.log(this.campaign_list_name);
      // this.checkCampaign()
      return this.campaign_list_name;
    },
    async getTagsActive() {
      const res = await axios.get("/coupon/tags-campaign");
      this.name_tag = res.data.data.listTags;
      // console.log(this.name_tag);
      return this.name_tag;
    }
    // getListDepartmentstore() {
    //   let check_admin_type = this.$session.get("layer_type");
    //   if (check_admin_type == "center") {
    //     this.axios
    //       .get(
    //         process.env.VUE_APP_API_WEB_ADMIN +
    //           "/api/v1/department-store?channelId=" +
    //           this.$session.get("layer_refId"),
    //         this.$session.get("header_web_admin")
    //       )
    //       .then(response => {
    //         this.list_department = response.data;

    //         if (this.show_campaign.departmentStoreId != "") {
    //           for (let j = 0; j < this.list_department.length; j++) {
    //             if (
    //               this.show_campaign.departmentStoreId == this.list_department[j].departmentStoreId
    //             ) {
    //               this.name_department = this.list_department[j];
    //               this.getCampaignDepartment(this.list_department[j]);
    //             }
    //           }
    //         }
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       });
    //   } else {
    //     this.axios
    //       .get(
    //         process.env.VUE_APP_API_WEB_ADMIN + "/api/v1/department-store",
    //         this.$session.get("header_web_admin")
    //       )
    //       .then(response => {
    //         this.list_department = response.data;

    //         if (this.show_campaign.departmentStoreId != "") {
    //           for (let j = 0; j < this.list_department.length; j++) {
    //             if (
    //               this.show_campaign.departmentStoreId == this.list_department[j].departmentStoreId
    //             ) {
    //               this.name_department = this.list_department[j];
    //               this.getCampaignDepartment(this.list_department[j]);
    //             }
    //           }
    //         }
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       });
    //   }
    // },
  }
};
</script>

<style></style>
