<template>
  <v-dialog v-model="localDialog" max-width="768px">
    <v-card style="border-radius: 6px">
      <div class="foundation-form-container">
        <div class="form-title">เพิ่มคูปอง</div>
        <v-form ref="form" lazy-validation v-model="valid">
          <div class="form-container">
            <div class="form-input">
              <div>คูปอง</div>
              <i-select-ss
                v-model="coupon_select"
                label="เลือกคูปอง"
                outlined
                dense
                :url="url"
                item-text="couponName"
                item-value="couponId"
              ></i-select-ss>
            </div>
          </div>
          <div class="form-container">
            <div class="form-input">
              <div>ราคา</div>
              <i-text-field v-model="price" :rules="rules" label="ระบุราคา" type="number" />
            </div>
          </div>
          <div class="form-container" v-if="!coupon_select">
            <div class="form-input">
              <div>รายละเอียดคูปอง</div>
              <div class="form-text-detail">กรุณาเลือกคูปองก่อน</div>
            </div>
          </div>
          <div class="form-container" v-else>
            <div class="form-input">
              <!-- <div>รายละเอียดคูปอง</div>
              <div class="card-image">
                <img :src="localImage" />
                
              </div> -->
              <div>
                <v-row no-gutters>
                  <v-col cols="12">
                    <p>รายละเอียดคูปอง</p>
                  </v-col>
                  <v-col cols="12" align="center">
                    <img :src="image" v-if="image" />
                    <img :src="image_preview" v-else />
                  </v-col>
                </v-row>
              </div>
              <div class="form-container">
                <v-expansion-panels v-model="panel" accordion multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header style="color: #0d47a1"
                      >ข้อมูลคูปอง
                      <template v-slot:actions>
                        <v-icon color="primary"> $expand </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="form-text">ชื่อคูปอง</div>
                      <div class="form-text-detail">{{ items.couponDetail.couponName }}</div>
                      <div class="form-text">โค้ดส่วนลด</div>
                      <div class="form-text-detail">{{ items.couponDetail.couponCode }}</div>
                      <!-- <div class="form-text">ราคา</div>
                      <div class="form-text-detail" v-if="price">{{ price }} บาท</div> -->
                      <div class="form-text">รายละเอียดคูปอง</div>
                      <div class="form-text-detail">
                        {{ items.couponDetail.couponDescription }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header style="color: #0d47a1"
                      >กำหนดระยะเวลาคูปอง
                      <template v-slot:actions>
                        <v-icon color="primary"> $expand </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="form-text">ระยะเวลาเก็บคูปอง</div>
                      <div class="form-text-detail">
                        {{ getCouponCollectDate(items.couponDate.collectStartDate) }} -
                        {{ getCouponCollectDate(items.couponDate.collectEndDate) }}
                      </div>
                      <div class="form-text">ระยะเวลาใช้งานคูปอง</div>
                      <div class="form-text-detail">
                        {{ getCouponCollectDate(items.couponDate.useStartDate) }} -
                        {{ getCouponCollectDate(items.couponDate.useEndDate) }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header style="color: #0d47a1"
                      >กำหนดจำนวนและสิทธิคูปอง
                      <template v-slot:actions>
                        <v-icon color="primary"> $expand </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <p class="form-text py-0">ประเภทของส่วนลด</p>
                            <p
                              class="form-text-detail py-0"
                              v-if="items.couponCenterDetail.discountType == 'any'"
                            >
                              คูปองส่วนลดค่าสินค้า บริการ และค่าขนส่ง
                            </p>
                            <p
                              class="form-text-detail py-0"
                              v-else-if="items.couponCenterDetail.discountType == 'goods'"
                            >
                              คูปองส่วนลดค่าสินค้าและบริการ
                            </p>
                            <p
                              class="form-text-detail py-0"
                              v-else-if="items.couponCenterDetail.discountType == 'delivery'"
                            >
                              คูปองส่วนลดค่าจัดส่ง
                            </p>
                          </v-col>
                          <v-col cols="6">
                            <p class="form-text py-0">จำนวนคูปอง</p>
                            <p class="form-text-detail py-0">
                              {{ items.couponRule.quota }}
                            </p></v-col
                          >
                          <v-col cols="6">
                            <p class="form-text py-0">จำนวนคูปองที่ใช้ได้จริง</p>
                            <p class="form-text-detail py-0">
                              {{ items.couponRule.collectQuota }}
                            </p></v-col
                          >
                          <v-col cols="12">
                            <p class="form-text py-0">สิทธิการใช้คูปอง</p>
                            <p class="form-text-detail py-0" v-if="items.couponRule.userCap == 1">
                              1 คนต่อ 1 สิทธิการใช้
                            </p>
                            <p class="form-text-detail py-0" v-else>ไม่จำกัดสิทธิ์การใช้</p>
                          </v-col>
                          <v-col cols="6">
                            <p class="form-text py-0">ประเภทส่วนลด</p>
                            <p class="form-text-detail py-0">
                              {{ items.couponRule.discountAmount }} บาท
                            </p></v-col
                          >
                          <v-col cols="6">
                            <p class="form-text py-0">ค่าใช้จ่ายขั้นต่ำ</p>
                            <p class="form-text-detail py-0">
                              {{ items.couponRule.spendMinimum }} บาท
                            </p></v-col
                          >
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header style="color: #0d47a1">
                      กำหนดการเข้าร่วมแคมเปญ
                      <template v-slot:actions>
                        <v-icon color="primary"> $expand </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="form-text">เลือกห้าง</div>
                      <div
                        class="form-text-detail"
                        v-if="items.couponCenterDetail.departmentStoreId"
                      >
                        {{ items.couponCenterDetail.departmentStoreId }}
                      </div>
                      <div class="form-text-detail" v-else>ไม่ระบุ</div>
                      <div class="form-text">เลือกแคมเปญ</div>
                      <div class="form-text-detail" v-if="items.couponCenterDetail.campaign">
                        {{ items.couponCenterDetail.campaign }}
                      </div>
                      <div class="form-text-detail" v-else>-</div>
                      <div class="form-text">แท็กที่เลือก</div>
                      <div class="form-text-detail" v-if="items.couponCenterDetail.tags">
                        <v-chip-group active-class="primary--text" column>
                          <v-chip v-for="tag in items.couponCenterDetail.tags" :key="tag">
                            {{ tag }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                      <div class="form-text-detail" v-else>ไม่ระบุ</div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </div>
          <div class="form-container">
            <v-row no-gutters class="mt-3">
              <v-col cols="12" align="end">
                <v-btn
                  outlined
                  color="#1E4D94"
                  style="text-transform: none"
                  class="ma-2"
                  @click="localDialog = false"
                  >ยกเลิก</v-btn
                >
                <v-btn color="primary" class="ma-2" @click="handleClick()">บันทึก</v-btn>
              </v-col>
              <!-- <v-col cols="6">
                <v-btn color="primary">บันทึก</v-btn>
              </v-col> -->
            </v-row>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "clients/axios";
// import moment from "moment";
export default {
  props: {
    value: {},
    id: {},
    name: {},
    isCreate: {
      default: true
    },
    isolate: {
      default: false
    },
    data: {
      default: () => {}
    },
    agencyId: {}
  },
  data: () => ({
    lacalName: null,
    coupon_select: null,
    price: null,
    panel: [0],
    switch_status: true,
    items: null,
    image: null,
    image_preview: null,
    valid: true
    // disabled: false
  }),
  watch: {
    coupon_select(val) {
      console.log("coupon_select", val);
      this.handleSelectCoupon();
    },
    localDialog(val) {
      val || this.close();
    }
  },
  computed: {
    localDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    url() {
      return `/donate-coupon/listcoupon`;
    },
    rules() {
      return [v => !!v || "กรุณากรอกราคา", v => parseInt(v) > 0 || "กรุณากรอกจำนวนมากกว่า 0 บาท"];
    }
  },
  created() {
    // this.coupon_select = null;
    // console.log(this.coupon_select);
  },
  methods: {
    async handleSelectCoupon() {
      try {
        const couponId = this.coupon_select;
        console.log(couponId);
        const res = await axios.get(`/donate-coupon/coupondetail?couponId=${couponId}`);
        const { data } = res.data;
        this.items = data;
        this.image = this.items.couponImagePath;
        // console.log(this.image);
        this.image_preview = require("assets/image/preview.png");
        console.log(this.items);
        this.lastUpdateTime = this.items.couponDate.collectStartDate;
      } catch (error) {
        console.log(error);
      }
    },
    getCouponCollectDate(date) {
      if (date != null) {
        let dd = date.substr(8, 2);
        let mm = date.substr(5, 2);
        let yy = date.substr(0, 4);
        let datestart = dd + "/" + mm + "/" + yy;
        let startDateString = datestart;

        let s_hour = date.substr(11, 2);
        let s_minute = date.substr(14, 2);
        return startDateString + "  " + s_hour + ":" + s_minute + " น.";
      } else if (date == null) {
        let startDateString = "ไม่ระบุวันสิ้นสุด";
        return startDateString;
      }
    },
    async handleClick() {
      try {
        if (this.$refs.form.validate()) {
          let data = {
            agencyProjectId: this.agencyId,
            couponId: this.items.couponId,
            couponName: this.items.couponDetail.couponName,
            price: parseInt(this.price)
          };
          const res = await axios.post(`/donate-coupon/coupondonate`, data);
          const data_res = res.data;
          if (data_res.message == "คูปองนี้อยู่ในโครงการบริจาคแล้ว") {
            this.$dialog({
              // title: "Error",
              showCancel: false,
              type: "warning",
              desc: `คูปองนี้อยู่ในโครงการบริจาคแล้ว`
            }).then(() => {
              // this.date = null;
              this.localDialog = false;
              this.$eventBus.$emit("reload-datatable");
            });
          } else {
            this.$dialog({
              // title: "Error",
              showCancel: false,
              type: "success",
              desc: `คุณได้เพิ่มคูปองเรียบร้อยแล้ว`
            }).then(() => {
              // this.date = null;
              this.localDialog = false;
              this.$eventBus.$emit("reload-datatable");
            });
          }
        }

        // }
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.coupon_select = null;
      this.price = null;
    }
  }
};
</script>

<style scoped>
.foundation-form-container {
  padding: 28px;
}
.form-text {
  padding: 12px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #333333;
}
.form-text-detail {
  padding: 12px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #aeaeae;
}
.form-title {
  padding: 12px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #000;
}
.card-image {
  flex: 3;
  overflow: hidden;
  border-radius: 10px;
  max-width: 400px;
  height: 180px;
  min-width: 100px;
}
.form-container {
  display: flex;
}
.form-input {
  flex: 3;
}
.form-switch {
  flex: 3;
}
.form-action {
  flex: 1;
  padding-left: 10px;
}
@media only screen and (max-width: 678px) {
  .form-container {
    flex-direction: column;
  }
}
</style>
