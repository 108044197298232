// export const columns = [
//   { text: "ชื่อคูปอง", value: "name" },
//   { text: "ราคา", value: "price" },
//   { text: "สถานะ", value: "status", componentName: "status-switches" },
//   { text: "", value: "action", componentName: "coupon-project-action" }
// ];
export const columns = [
  { text: "ชื่อคูปอง", value: "couponName" },
  { text: "ราคา", value: "price", width: 145 },
  { text: "สถานะ", value: "status", componentName: "status-switches", width: 250 },
  { text: "", value: "action", componentName: "coupon-project-action", width: 145 }
];
