<template>
  <div>
    <div class="mt-3">
      <i-card>
        <i-header title="กำหนดจำนวนและสิทธิ์คูปอง"><div></div></i-header>
        <div>
          <v-row
            class="mt-3"
            align="center"
            justify="center"
            v-if="this.mode.mode == 'CREATE' || this.mode.mode == 'EDIT'"
          >
            <v-col cols="12" class="pb-0" v-if="create_type != 'MERCHANT'">
              <h4 class="font-weight-regular">
                ประเภทของส่วนลด
              </h4>
            </v-col>
            <v-col cols="12" class="pt-1 pb-0" v-if="create_type != 'MERCHANT'">
              <v-select
                :items="list_discount_type"
                v-model="prop_permission_coupon.discountType_name"
                item-text="title"
                item-value="value"
                outlined
                placeholder="เลือกประเภทของส่วนลด"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <h4 class="font-weight-regular">จำนวนคูปอง<span class="red--text">*</span></h4>
            </v-col>
            <v-col cols="12" class="pt-1 pb-0">
              <v-text-field
                v-model="prop_permission_coupon.collectQuota"
                class=""
                placeholder="จำนวนคูปองที่สามารถเก็บได้ทั้งหมด"
                outlined
                :rules="
                  check_disable_collectquota == false
                    ? data_donation == false
                      ? rules_collectQuota_keep_future
                      : rules_collectQuota
                    : rules_collectQuota_keep_past
                "
                :maxlength="3"
                type="number"
                @keypress="validate_numeric($event)"
                flat
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4 class="font-weight-regular">
                จำนวนคูปองที่ใช้ได้จริง<span class="red--text">*</span>
              </h4>
            </v-col>
            <!-- ********************** COUPON ********************** -->
            <v-row class="px-4" align="center" justify="center">
              <!-- <pre>{{ prop_permission_coupon }}</pre> -->
              <v-col cols="12" class="pt-1 pb-0">
                <v-text-field
                  v-model="prop_permission_coupon.quota"
                  placeholder="จำนวนคูปองที่สามารถใช้ได้ทั้งหมด"
                  outlined
                  :disabled="prop_permission_coupon.collectQuota == ''"
                  :rules="
                    check_disable_quota == false
                      ? data_donation == false
                        ? rules_quota_use_future
                        : rules_quota
                      : rules_quota_use_past
                  "
                  :maxlength="3"
                  @keypress="validate_numeric($event)"
                  type="number"
                  flat
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 
            <v-col cols="12" class="pb-0">
              <h4 class="font-weight-regular">สิทธิ์การใช้คูปอง<span class="red--text">*</span></h4>
            </v-col>

            <v-col cols="3" class="py-0" style="margin-top:-12px">
              <v-radio-group v-model="prop_permission_coupon.userCap">
                <v-radio
                  class="py-1"
                  style="width:80%"
                  v-for="(item, index) in items_userCap"
                  :key="index"
                  :label="item.title"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="9"> </v-col>
            <v-row class="px-3" v-if="prop_permission_coupon.userCap == 'custom'">
              <v-col cols="2" class="py-0">
                <v-text-field value=" 1 คน ต่อ" readonly outlined flat dense></v-text-field>
              </v-col>
              <v-col cols="10" class="py-0">
                <v-text-field
                  v-model="prop_permission_coupon.userCap_text_field"
                  type="number"
                  placeholder="ระบุจำนวนสิทธิ์"
                  outlined
                  suffix="สิทธิ์"
                  flat
                  dense
                ></v-text-field>
              </v-col>
            </v-row> -->

            <v-col cols="12" class="pb-0">
              <h4 class="font-weight-regular ">
                ประเภทส่วนลด
                <span class="red--text">*</span>
                <!-- <span class="red--text">* (เลือกได้ 1 รูปแบบ)</span> -->
              </h4>
            </v-col>

            <v-col cols="3" class="py-0" style="margin-top:-12px">
              <v-radio-group v-model="prop_permission_coupon.discountType">
                <v-radio
                  v-for="item in items_radio_rights"
                  :key="item.key"
                  :value="item.key"
                  class="py-1"
                  style="width:80%"
                >
                  <template v-slot:label>
                    <span
                      class="primary--text"
                      v-if="item.title == prop_permission_coupon.discountType"
                      >{{ item.title }}</span
                    >
                    <span v-else>{{ item.title }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="9"> </v-col>
            <v-col cols="12" class="py-0" v-if="prop_permission_coupon.discountType == 'money'">
              <v-text-field
                v-model="prop_permission_coupon.discountAmount_money"
                :rules="rules_discount"
                @keypress="validate_numeric($event)"
                placeholder="กรุณาใส่ส่วนลดที่ต้องการ"
                suffix="บาท"
                outlined
                type="number"
                flat
                dense
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              class="py-0"
              v-else-if="prop_permission_coupon.discountType == 'percent'"
            >
              <v-text-field
                v-model="prop_permission_coupon.discountAmount_percent"
                class=""
                placeholder="กรุณาใส่ส่วนลดที่ต้องการ"
                suffix="%"
                type="number"
                outlined
                flat
                dense
              ></v-text-field>
            </v-col>

            <v-row
              class="ml-10"
              align="center"
              justify="center"
              v-if="prop_permission_coupon.discountType == 'percent'"
            >
              <v-col cols="12" class="py-1">
                <h4 class="font-weight-medium">ส่วนลดสูงสุด</h4>
              </v-col>
              <v-col cols="4" align="left">
                <v-btn
                  :color="this.colorButtonAmount"
                  block
                  outlined
                  @click="btnMaxDiscount('limitamount')"
                  >จำกัดจำนวนเงิน</v-btn
                >
              </v-col>
              <v-col cols="4" align="left">
                <v-btn
                  :color="this.colorButtonless"
                  block
                  outlined
                  @click="btnMaxDiscount('limitless')"
                  >ไม่จำกัด</v-btn
                >
              </v-col>
              <v-col cols="4"> </v-col>

              <v-col
                cols="12"
                class="pl-3 pr-6"
                v-if="prop_permission_coupon.type_discount == 'limitamount'"
              >
                <v-text-field
                  v-model="prop_permission_coupon.discountMaximum"
                  class=""
                  placeholder="กรุณาใส่ส่วนลดที่ต้องการ"
                  type="number"
                  suffix="บาท"
                  outlined
                  flat
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="12" class="py-1">
              <h4 class="font-weight-regular">ค่าใช้จ่ายขั้นต่ำ</h4>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="prop_permission_coupon.spendMinimum"
                placeholder="ค่าใช้จ่ายขั้นต่ำ"
                :rules="rules_discount_amount"
                @keypress="validate_numeric($event)"
                type="number"
                suffix="บาท"
                outlined
                flat
                dense
              ></v-text-field>
              <h5 class="red--text font-weight-regular py-0" style="margin-top:-5px">
                * ยอดใช้จ่ายขั้นต่ำของการใช้สิทธิ์คูปอง
              </h5>
            </v-col>

            <v-col cols="12" class="pt-0" v-if="this.create_type == 'MAIN-REWARD'">
              <h4 class="font-weight-regular">จำนวนแต้ม/แสตมป์</h4>
            </v-col>
            <v-col cols="12" class="py-0" v-if="this.create_type == 'MAIN-REWARD'">
              <v-text-field
                v-model="prop_permission_coupon.pointToReward"
                placeholder="จำนวนแต้ม/แสตมป์ที่ต้องการให้แลกของรางวัล"
                outlined
                class="pt-1"
                type="number"
                suffix="แต้ม"
                flat
                dense
              ></v-text-field>
              <h5 class="red--text font-weight-regular py-0" style="margin-top:-15px">
                * จำนวนแต้ม/แสตมป์ของการใช้สิทธิ์แลกของรางวัล
              </h5>
            </v-col>
          </v-row>
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>
import * as rules from "@/javascript/rules.js";
export default {
  props: {
    coupon_type: {
      type: String,
      require: true,
      default: ""
    },
    create_type: {
      type: String,
      require: true,
      default: ""
    },
    mode: {
      type: Object,
      require: true,
      default() {}
    },
    prop_permission_coupon: {
      type: Object,
      require: true,
      default() {}
    },
    prop_campaing: {
      type: Object,
      require: true,
      default() {}
    },
    item_coupon_data: {
      type: Object,
      require: true,
      default() {}
    }
  },
  data() {
    return {
      //rules
      // จำนวนคูปอง
      rules_collectQuota: [
        v => /^[0-9]\d*$|^$/.test(v) || "* กรุณาระบุจำนวนคูปองให้ถูกต้อง",
        v => (!isNaN(parseInt(v)) && v >= 0) || "* กรุณาระบุจำนวนคูปองให้ถูกต้อง",
        v =>
          (!isNaN(parseInt(v)) && v >= 0 && v <= 100000) ||
          "กรุณาระบุจำนวนคูปองให้ถูกต้อง และไม่เกิน 100,000"
      ],
      rules_collectQuota_keep_future: [
        v => /^[1-9]\d*$|^$/.test(v) || "* กรุณาระบุจำนวนคูปองให้ถูกต้อง",
        v => (!isNaN(parseInt(v)) && v >= 1) || "* กรุณาระบุจำนวนคูปอง มากกว่า 0",
        v =>
          (!isNaN(parseInt(v)) && v >= 1 && v <= 100000) ||
          "กรุณาระบุจำนวนคูปองให้ถูกต้อง และไม่เกิน 100,000"
      ],
      rules_discount_amount: [
        // v => /^[1-9]\d*$|^$/.test(v) || "* กรุณาระบุจำนวนค่าใช้จ่ายขั้นต่ำถูกต้อง",
        v =>
          (!isNaN(parseInt(v)) && v >= 0) ||
          "* กรุณาระบุจำนวนค่าใช้จ่ายขั้นต่ำ มากกว่า 0 หรือเท่ากับ 0"
        // v =>
        //   (!isNaN(parseInt(v)) && v >= 1 && v <= 100000) ||
        //   "กรุณาระบุจำนวนคูปองให้ถูกต้อง และไม่เกิน 100,000"
      ],
      rules_discount: [
        v => /^[1-9]\d*$|^$/.test(v) || "* กรุณาระบุจำนวนส่วนลดให้ถูกต้อง",
        v => (!isNaN(parseInt(v)) && v >= 1) || "* กรุณาระบุจำนวนส่วนลด มากกว่า 0 "
        // v =>
        //   (!isNaN(parseInt(v)) && v >= 1 && v <= 100000) ||
        //   "กรุณาระบุจำนวนคูปองให้ถูกต้อง และไม่เกิน 100,000"
      ],
      rules_collectQuota_keep_past: [
        v => /^[1-9]\d*$|^$/.test(v) || "* กรุณาระบุจำนวนคูปองให้ถูกต้อง",
        v => (!isNaN(parseInt(v)) && v >= 1) || "* กรุณาระบุจำนวนคูปอง มากกว่า 0",
        v =>
          (!isNaN(parseInt(v)) && v >= 1 && v <= 100000) ||
          "กรุณาระบุจำนวนคูปองให้ถูกต้อง และไม่เกิน 100,000",
        v =>
          (v &&
            parseInt(this.prop_permission_coupon.collectQuota) >=
              parseInt(this.collectQuota_old)) ||
          "* กรุณาระบุ จำนวนคูปอง ให้มากกว่า " + this.collectQuota_old
      ],
      // จำนวนคูปองที่ใช้ได้จริง
      rules_quota: [
        v => /^[0-9]\d*$|^$/.test(v) || "* กรุณาระบุจำนวนคูปองที่ใช้ได้จริงให้ถูกต้อง",
        v => (!isNaN(parseInt(v)) && v >= 0) || "* กรุณาระบุจำนวนคูปองที่ใช้ได้จริงให้ถูกต้อง",
        v =>
          (!isNaN(parseInt(v)) && v >= 0 && v <= 100000) ||
          "กรุณาระบุจำนวนคูปองที่ใช้ได้จริงให้ถูกต้อง และไม่เกิน 100,000",
        v =>
          (v &&
            parseInt(this.prop_permission_coupon.collectQuota) >=
              parseInt(this.prop_permission_coupon.quota)) ||
          "* กรุณาระบุ จำนวนคูปองที่ใช้ได้จริง ไม่เกินกว่าจำนวนคูปอง"
      ],
      rules_quota_use_future: [
        v => /^[1-9]\d*$|^$/.test(v) || "* กรุณาระบุจำนวนคูปองที่ใช้ได้จริงให้ถูกต้อง",
        v => (!isNaN(parseInt(v)) && v >= 1) || "* กรุณาระบุจำนวนคูปองที่ใช้ได้จริง มากกว่า 0",
        v =>
          (!isNaN(parseInt(v)) && v >= 1 && v <= 100000) ||
          "กรุณาระบุจำนวนคูปองที่ใช้ได้จริงให้ถูกต้อง และไม่เกิน 100,000",
        v =>
          (v &&
            parseInt(this.prop_permission_coupon.collectQuota) >=
              parseInt(this.prop_permission_coupon.quota)) ||
          "* กรุณาระบุ จำนวนคูปองที่ใช้ได้จริง ไม่เกินกว่าจำนวนคูปอง"
      ],
      rules_quota_use_past: [
        v => /^[1-9]\d*$|^$/.test(v) || "* กรุณาระบุจำนวนคูปองให้ถูกต้อง",
        v => (!isNaN(parseInt(v)) && v >= 1) || "* กรุณาระบุจำนวนคูปอง มากกว่า 0",
        v =>
          (!isNaN(parseInt(v)) && v >= 1 && v <= 100000) ||
          "กรุณาระบุจำนวนคูปองให้ถูกต้อง และไม่เกิน 100,000",
        v =>
          (v &&
            parseInt(this.prop_permission_coupon.collectQuota) >=
              parseInt(this.prop_permission_coupon.quota)) ||
          "* กรุณาระบุ จำนวนคูปองที่ใช้ได้จริง ไม่เกินกว่าจำนวนคูปอง",
        v =>
          (v && parseInt(this.prop_permission_coupon.quota) >= parseInt(this.quota_old)) ||
          "* กรุณาระบุ จำนวนคูปองที่ใช้ได้จริง ให้มากกว่า " + this.quota_old
      ],
      collectQuota_keep_past: {},
      collect_quota_coupon: rules.quota_coupon,
      quota_coupon: rules.quota_coupon,
      user_cap: rules.user_cap,
      user_cap_text_field: rules.user_cap_text_field,
      discount_type: rules.discount_type,
      discount_amount_percent: rules.discount_amount_percent,
      discount_amount_money: rules.discount_amount_money,
      point_to_reward: rules.point_to_reward,
      spend_minimum: rules.spend_minimum,
      discount_maximum: rules.discount_maximum,
      items_userCap: [
        {
          value: "one",
          title: "1 คนต่อ 1 สิทธิ์การใช้"
        },
        {
          value: "unlimited",
          title: "ไม่จำกัดสิทธิ์การใช้"
        },
        {
          value: "custom",
          title: "กำหนดเอง"
        }
      ],
      items_radio_rights: [
        {
          index: 0,
          key: "money",
          title: "ส่วนลด (เป็นจำนวนเงิน)"
        }
        // {
        //   index: 1,
        //   key: "percent",
        //   title: "ส่วนลด (เป็น %)"
        // }
      ],
      list_people: [
        {
          value: 1,
          title: "1 คน"
        }
      ],
      panel: [0],
      discount_type_name: "",
      list_discount_type: [
        {
          value: "any",
          title: "คูปองส่วนลดค่าสินค้า บริการ และค่าขนส่ง"
        },
        {
          value: "goods",
          title: "คูปองส่วนลดค่าสินค้าและบริการ"
        },
        {
          value: "delivery",
          title: "คูปองส่วนลดค่าจัดส่ง"
        }
      ],
      // list_discount_type: [
      //   "คูปองส่วนลดค่าสินค้า บริการ และค่าขนส่ง",
      //   "คูปองส่วนลดค่าสินค้าและบริการ",
      //   "คูปองส่วนลดค่าจัดส่ง",
      // ],
      prop_collectQuota: null,
      prop_collectRemaining: null,
      prop_quota: null,
      prop_remaining: null,
      tags_donation: false,
      data_donation: false,
      collectQuota_old: null,
      quota_old: null
    };
  },
  computed: {
    checkSpendMinimum() {
      return this.prop_permission_coupon.spendMinimum;
    },
    colorButtonAmount() {
      if (this.prop_permission_coupon.type_discount == "limitamount") {
        return "primary";
      }
      return null;
    },
    colorButtonless() {
      if (this.prop_permission_coupon.type_discount == "limitless") {
        return "primary";
      }
      return null;
    },
    check_unlimit() {
      return this.prop_permission_coupon.check_box_unlimit;
    },
    check_disable_collectquota() {
      if (this.mode.mode == "EDIT") {
        if (parseInt(this.prop_collectRemaining) == parseInt(this.prop_collectQuota)) {
          return false;
        } else if (parseInt(this.prop_collectRemaining) < parseInt(this.prop_collectQuota)) {
          return true;
        }
      }
      return false;
    },
    check_disable_quota() {
      if (this.mode.mode == "EDIT") {
        if (parseInt(this.prop_remaining) == parseInt(this.prop_quota)) {
          return false;
        } else if (parseInt(this.prop_remaining) < parseInt(this.prop_quota)) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    item_coupon_data(val) {
      // console.log("item_coupon_data", val);
      this.prop_collectQuota = val.collectQuota;
      this.prop_collectRemaining = val.collectRemaining;
      this.prop_quota = val.quota;
      this.prop_remaining = val.remaining;
    },

    // "prop_permission_coupon.collectQuota"(val) {
    //   // console.log("====", val);
    //   if (parseInt(this.collectQuota_old) != parseInt(val)) {
    //     return (this.prop_permission_coupon.quota = null);
    //   }
    // },
    check_unlimit(val) {
      if (val == true) {
        return (this.prop_permission_coupon.quota = null);
      }
    },
    checkSpendMinimum(val) {
      let a = 0;
      for (let index = 0; index < val.length; index++) {
        if (val[index] == 0) {
          a++;
        }
      }
      if (parseInt(val) <= 0 && a > 1 && val.length > 1) {
        return (this.prop_permission_coupon.spendMinimum = 0);
      }
    },
    "prop_campaing.tags"(val) {
      // console.log("+++", val);
      let index = 0;
      this.tags_donation = false;
      if (this.mode.mode == "CREATE") {
        if (val != null) {
          if (val.length != 0) {
            for (index; index < val.length; index++) {
              // console.log("ggg", val[index]);
              if (val[index] == "donation") {
                this.tags_donation = true;
              }
            }
          }
        }
      } else {
        this.tags_donation = false;
      }
      // console.log("donation", this.tags_donation);
      // console.log(index);
    },
    tags_donation(val) {
      // console.log("***", val);
      if (val == false) {
        if (this.prop_permission_coupon.collectQuota == 0) {
          return (this.prop_permission_coupon.collectQuota = null);
        }
      }
      this.data_donation = val;
    }
  },
  methods: {
    btnMaxDiscount(type) {
      // console.log(type)
      if (type == "limitless") {
        this.prop_permission_coupon.type_discount = "limitless";
      } else {
        this.prop_permission_coupon.type_discount = "limitamount";
      }
    },
    statusModePanels() {
      if (this.mode.mode == "EDIT" || this.mode.mode == "NORMAL") {
        return true;
      } else {
        return false;
      }
    },
    validate_numeric(e) {
      if (e.charCode >= 48 && e.charCode <= 57) {
        return true;
      } else {
        e.preventDefault();
      }
    }
  }
};
</script>

<style></style>
