<template>
  <v-btn
    :depressed="depressed"
    :style="{
      background: '#1E4D94',
      color: '#fff',
      borderRadius: '6px'
    }"
    :block="block"
    @click="e => $emit('click', e)"
    :loading="loading"
    :disabled="disabled"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    block: {
      default: () => true
    },
    loading: {
      default: () => false
    },
    depressed: {
      default: () => true
    },
    disabled: {
      default: () => false
    }
  }
};
</script>
