<template>
  <div>
    <FilterInput
      :filterStatus="false"
      name-label="ชื่อหน่วยงาน"
      name-input-label="ค้นหารายชื่อหน่วยงาน"
      :agency="agency"
      @handle-agency="handleAgency"
    />
    <i-card mt-3>
      <i-header title="template หน่วยงาน">
        <div class="action-container">
          <!-- <div class="action-button">
            <i-button @click="dialog = true">เพิ่มคูปอง home isolation</i-button>
          </div> -->
        </div>
      </i-header>
      <Datatables :columns="columns" :url="url" />
    </i-card>
    <!-- <coupon-isolate-editable v-model="dialog" :isCreate="true" :data="{}" /> -->
  </div>
</template>

<script>
// import moment from "moment";
import { columns } from "data/tables/coupon-template-view";
import Datatables from "components/datatables/index.vue";
import FilterInput from "./coupon/modules/filter-input";
// import CouponIsolateEditable from "components/dialog/coupon-isolate-editable.vue";
export default {
  data: () => ({
    columns,
    startDate: null,
    days: 1,
    dayList: [],
    agency: null,
    name: "",
    dialog: false,
    url: `/coupon/alltemplate`,
    dateRange: []
  }),
  computed: {
    tomorow() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      date.setHours(0);
      return date;
    }
  },
  components: {
    Datatables,
    FilterInput
    // CouponIsolateEditable
  },
  async mounted() {},
  watch: {
    agency() {
      this.url = `/coupon/alltemplate?agencyId=${this.agencyId ? this.agencyId : ""}  `;
      // this.$eventBus.$emit("reload-datatable");
    }
    // agency() {
    //   this.url = `/coupon/alltemplate/`;
    // }
  },
  methods: {
    handleAgency(value) {
      console.log(value);
      // this.url = `/coupon/alltemplate/`
      // this.url = '/coupon/alltemplate?agencyId=61ee6fa69153ab2dde897cb4'
      this.agencyId = value ? value : "";
      this.url = `/coupon/alltemplate?agencyId=${this.agencyId}`;
    }
  }
};
</script>

<style scope>
.date-input {
  display: flex;
}
</style>
