<template>
  <div>
    <div>{{ name }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["agencyId"],
  data: () => ({}),

  computed: {
    ...mapState("agency", ["agenciesHomei"]),
    name() {
      for (let index = 0; index < this.agenciesHomei.length; index++) {
        if (this.agenciesHomei[index].id == this.agencyId) {
          return this.agenciesHomei[index].name;
        }
      }
      return "";
    }
  },

  created() {
    this.url = `/agency/isolate`;
    // axios
    //   .get(this.url)
    //   .then(res => {
    //     const { data } = res.data;
    //     this.data = data;
    //     for (let index = 0; index < this.data.length; index++) {
    //       if (this.data[index].id == this.agencyId) {
    //         this.name = this.data[index].name;
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     this.loading = false;
    //   });
  },
  methods: {}
};
</script>

<style></style>
