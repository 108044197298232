<template>
  <div>
    <div class="foundation-action-container">
      <!-- <v-btn icon @click="handEdit" :data="data">
        <v-icon>mdi-pencil</v-icon>
      </v-btn> -->
      <!-- <v-btn :disabled="canEdit" icon class="btn-reject" @click="handleDelete">
        <v-icon color="red">mdi-trash-can-outline</v-icon>
      </v-btn> -->
      <v-btn icon @click="handleDetail(data)">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </div>
    <coupon-isolate-editable v-model="dialog" :isCreate="false" :data="data" />
  </div>
</template>

<script>
import CouponIsolateEditable from "components/dialog/coupon-isolate-editable.vue";
// import moment from "moment";
import axios from "clients/axios";
export default {
  props: ["agencyId", "meal"],
  components: {
    CouponIsolateEditable
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    data() {
      return {
        // id: this.id,
        agencyId: this.agencyId,
        meal: this.meal
        // couponName: this.couponName,
        // day: this.day,
        // ets: this.ets,
        // refId: this.refId,
        // status: this.status ? this.status : "waiting"
      };
    }
  },
  methods: {
    handleDetail(data) {
      console.log(data);
      // this.$router.push(`/isulation/coupon/${this.id}`);
      this.$router.push(`/template-coupon/${this.agencyId}/${this.meal}`);
      console.log(this.agencyId, this.meal);
    },
    handleDelete() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc: "คุณต้องการลบคูปองนี้หรือไม่",
        icon: "mdi-trash-can-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ลบคูปอง",
        width: 450,
        onOk: async () => {
          await axios.delete(`/coupon/homeisolate/${this.id}`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้ลบลบคูปองเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.foundation-action-container {
  display: flex;
  justify-content: space-around;
}
</style>
