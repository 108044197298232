export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อคูปอง", value: "couponName" },
  // { text: "ไอดีโครงการ", value: "agencyProjectId" },
  { text: "รายละเอียดคูปอง", value: "couponDetail" },
  { text: "ราคา", value: "price" },
  { text: "สถานะ", value: "status", componentName: "status-donation-type" },
  { text: "", value: "", componentName: "see-donate-coupon" }
  // { text: "", value: "", componentName: "see-detail-donate" }
];
