var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"i-card",style:({
    background: '#fff',
    padding: '14px',
    borderRadius: '6px',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0px 0px 2px rgba(51, 51, 51, 0.25)'
  }),attrs:{"outlined":_vm.outlined}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }