<template>
  <div>{{ dataTimeFormat }}</div>
</template>

<script>
import moment from "moment";
export default {
  props: ["lastUpdateTime", "createTime", "useTime"],
  computed: {
    dataTimeFormat() {
      if (this.lastUpdateTime) {
        return this.lastUpdateTime ? moment(this.lastUpdateTime).format("DD/MM/YYYY HH:mm น.") : "";
      } else if (this.createTime) {
        return this.createTime ? moment(this.createTime).format("DD/MM/YYYY HH:mm น.") : "";
      } else if (this.useTime) {
        return this.useTime ? moment(this.useTime).format("DD/MM/YYYY HH:mm น.") : "";
      }
      return "";
    }
  }
};
</script>

<style></style>
