<template>
  <v-dialog v-model="dialog_notification" max-width="630">
    <v-app-bar flat color="primary">
      <v-row align="center" justify="center">
        <v-col align="center">
          <h4 class="font-weight-regular white--text mt-2">
            คุณต้องการสร้างคูปองโดยไม่กำหนดรูปภาพ ใช่หรือไม่
          </h4>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-card class="py-6 px-3">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col class="pt-2 pb-0" align="center" cols="12">
            <h3 class="font-weight-regular black--text">
              การสร้างคูปองโดยไม่กำหนดรูปภาพนั้น ระบบจะทำการใส่รูปภาพ <br />
              พื้นฐานของระบบ หากต้องการเพิ่มรูปภาพกรุณากดยกเลิก<br />
            </h3>
          </v-col>

          <v-col align="center" cols="12" class="py-0">
            <v-radio-group v-model="radio_accept" class="py-0">
              <v-radio value="accept" class="py-0" style="align-self: center;">
                <template v-slot:label>
                  <span class="black--text ">ยืนยันการสร้างคูปอง โดยไม่เลือกรูปภาพ</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center pt-0">
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-btn color="error" block @click="cancel()">ยกเลิก</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" outlined block @click="submit()" :disabled="!this.radio_accept"
              >ยืนยัน</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      dialog_notification: false,
      radio_accept: false
    };
  },
  methods: {
    open() {
      this.dialog_notification = true;
    },
    submit() {
      if (this.radio_accept == "accept") {
        this.$emit("setdataCreateUpdateCoupon");
        this.dialog_notification = false;
        this.radio_accept = false;
      }
    },
    cancel() {
      this.dialog_notification = false;
      this.radio_accept = false;
    }
  }
};
</script>
<style scoped></style>
