<template>
  <div>
    <v-btn text color="#1E4D94" @click="handleClick"
      ><v-icon style="margin-right: 5px">mdi-eye</v-icon>ดูข้อมูล
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["agencyProjectId"],
  methods: {
    handleClick() {
      this.$router.push(`/donate-detail/${this.agencyProjectId}`);
    }
  }
};
</script>

<style scoped>
.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200%;
  white-space: nowrap;
}
</style>
