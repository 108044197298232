export const status = [
  {
    itemValue: "active",
    itemText: "ยืนยันเรียบร้อย"
  },
  {
    itemValue: "expired",
    itemText: "ยกเลิก"
  },
  {
    itemValue: "inactive",
    itemText: "รอยืนยัน"
  }
];
