<template>
  <div :style="{ color: statusColor }">{{ statusText }}</div>
</template>

<script>
export default {
  props: ["status"],
  computed: {
    statusText() {
      switch (this.status) {
        case "active":
          return "ยืนยันเรียบร้อย";
        case "inactive":
          return "รอยืนยัน";
        case "expired":
          return "ยกเลิก";
        default:
          return "";
      }
    },
    statusColor() {
      switch (this.status) {
        case "inactive":
          return "#E9A016";
        case "active":
          return "#4E9F5B";
        case "expired":
          return "#D1392B";
        default:
          return "#00A0DA";
      }
    }
  }
};
</script>

<style></style>
