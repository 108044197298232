<template>
  <div class="header-container">
    <div class="header-title">{{ title }}</div>
    <slot v-if="hasDefault" />
    <div v-else class="header-action">
      <i-button v-if="actionTitle" @click="$emit('action-click')">{{ actionTitle }}</i-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: () => "title"
    },
    actionTitle: {
      default: () => null
    }
  },
  computed: {
    hasDefault() {
      return this.$slots.default;
    }
  }
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.header-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}
</style>
