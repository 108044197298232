<template>
  <div>
    <div class="mt-3">
      <i-card>
        <i-header title="ข้อมูลคูปอง"><div></div></i-header>
        <div>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <h4 class="font-weight-regular">ชื่อคูปอง <span class="red--text">*</span></h4>
            </v-col>
            <v-col cols="12" class="pt-1 pb-0">
              <v-text-field
                v-model="prop_detail.name_coupon"
                class=""
                placeholder="ระบุชื่อคูปอง"
                :rules="name_coupon"
                :maxlength="50"
                counter="50"
                outlined
                flat
                dense
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" class="py-0">
              <h4 class="font-weight-regular">โค้ดส่วนลด</h4>
            </v-col>
            <v-col cols="12" class="pt-1 pb-0">
              <v-text-field
                v-model="prop_detail.code_coupon"
                class="code"
                @keyup="uppercase"
                placeholder="ใส่รหัสส่วนลด 4 - 6 หลัก"
                v-mask="'NNNNNN'"
                outlined
                :maxlength="6"
                :minlength="4"
                counter="6"
                flat
                dense
              ></v-text-field>
            </v-col> -->

            <v-col cols="12" class="py-0">
              <h4 class="font-weight-regular">รายละเอียดคูปอง <span class="red--text">*</span></h4>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="prop_detail.description_coupon"
                :rules="description_coupon"
                flat
                :maxlength="250"
                counter="250"
                outlined
                placeholder="รายละเอียดคูปอง"
              ></v-textarea>
              <h5 class="font-weigth-regular red--text py-0">
                * ระบุรายละเอียดคูปอง วิธีใช้งาน และสิทธิประโยชน์สำหรับสมาชิกอย่างละเอียด
              </h5>
            </v-col>

            <v-col cols="12" class="pb-0">
              <h4 class="font-weight-regular">
                สถานะคูปอง
              </h4>
            </v-col>
            <v-col cols="12" class="pt-1 pb-0">
              <v-select
                :items="list_status_type"
                v-model="prop_detail.status"
                item-text="title"
                item-value="value"
                outlined
                placeholder="เลือกประเภทของสถานะ"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>
import * as rules from "@/javascript/rules.js";
export default {
  props: {
    coupon_type: {
      type: String,
      require: true,
      default: ""
    },
    prop_detail: {
      type: Object,
      require: true,
      default() {}
    },
    mode: {
      type: Object,
      require: true,
      default() {}
    }
  },
  data() {
    return {
      code: "",
      //rules
      code_coupon: rules.code_coupon,
      name_coupon: rules.name_coupon,
      description_coupon: rules.description_coupon,
      list_status_type: [
        {
          value: "active",
          title: "เปิดใช้งาน"
        },
        {
          value: "inactive",
          title: "ไม่เปิดใช้งาน"
        }
      ]
    };
  },
  methods: {
    uppercase() {
      this.code = this.code.toUpperCase();
    }
  }
};
</script>

<style></style>
