export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อหน่วยงาน", value: "agencyName" },
  { text: "ผู้ป่วยใหม่", value: "newPatients" },
  { text: "ผู้ป่วยที่ใช้งาน", value: "activePatients" },
  { text: "ผู้ป่วยที่ไม่ได้ใช้งาน", value: "inactivePatients" }
  // { text: "", value: "", componentName: "see-detail-donate" }
];
