<template>
  <!-- <div :style="{ color: statusColor }">
    <v-switch v-model="switch_status" color="success" hide-details></v-switch>{{ statusText }}
  </div> -->
  <div>
    <div>
      <v-row no-gutters>
        <v-col cols="4" v-if="statusText == 'คูปองหมดอายุ'">
          <v-switch v-model="switch_status" color="success" hide-details disabled></v-switch>
        </v-col>
        <v-col cols="4" v-else>
          <v-switch v-model="switch_status" color="success" hide-details></v-switch>
        </v-col>
        <v-col cols="4">
          <p class="mt-6">{{ statusText }}</p>
        </v-col>
      </v-row>
    </div>
    <!-- <div>เปิดใช้งาน</div> -->
  </div>
</template>

<script>
import axios from "clients/axios";
export default {
  props: ["status", "id"],
  data: () => ({
    // switch_status: true
  }),
  computed: {
    switch_status: {
      get() {
        switch (this.status) {
          case "active":
            return true;
          case "inacitve":
            return false;
          default:
            return false;
        }
      },
      async set(val) {
        await axios.put(`/donate-coupon/activecoupondonate/${this.id}`, {
          status: val ? "active" : "inactive"
        });
        this.$eventBus.$emit("reload-datatable");
      }
    },
    statusText() {
      switch (this.status) {
        case "inacitve":
          return "ปิดการใช้งาน";
        case "active":
          return "เปิดใช้งาน";
        case "expired":
          return "คูปองหมดอายุ";
        default:
          return "ปิดการใช้งาน";
      }
    }
  }
};
</script>

<style></style>
