<template>
  <div class="card-container" :style="{ boxShadow: `0px 2px 4px rgba(${color}, 0.25)` }">
    <div class="card-title" :style="{ color: `rgba(${color}, 1)` }">{{ title }}</div>
    <div class="card-value">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {},
    value: {},
    color: {}
  }
};
</script>

<style scoped>
.card-container {
  width: 265px;
  height: 150px;

  /* Color / Neutral / #FFFFFF */

  background: #ffffff;
  /* border: 3px solid #daedf4; */
  box-sizing: border-box;
  /* box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.25); */
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 24px 0;
}
.card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
}
.card-value {
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 75px;

  text-align: center;
  color: #333333;
}
</style>
