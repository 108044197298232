<template>
  <div>
    <div class="foundation-action-container">
      <v-btn icon>
        <v-icon @click="reportAction">mdi-file-chart</v-icon>
      </v-btn>
    </div>
    <coupon-report v-model="dialog" :cuponId="_id" />
  </div>
</template>

<script>
import CouponReport from "components/dialog/coupon-report.vue";
export default {
  components: {
    CouponReport
  },
  data: () => ({
    dialog: false
  }),
  props: ["_id"],
  methods: {
    reportAction() {
      this.dialog = true;
    }
  }
};
</script>

<style scope>
.foundation-action-container {
  display: flex;
  justify-content: space-around;
}
</style>
