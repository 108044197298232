<template>
  <div>
    <i-header title="รายงานคูปอง HomeIsolation"><div></div></i-header>

    <FilterInput :hasDate="true" @on-click="handleClick" />

    <i-header title="รายละเอียดผู้ป่วยทั้งหมด">
      <div class="action-container"></div>
    </i-header>
    <Datatables :columns="columns" :url="url" modelKey="agencyId" />
  </div>
</template>

<script>
import { columns } from "data/tables/report-coupon-home-isolation";
import Datatables from "components/datatables/index.vue";
import FilterInput from "./modules/filter-input.vue";
import moment from "moment";
export default {
  data: () => ({
    columns,
    name: "",
    url: `/syn/homeisolation/dashboard?date=${moment(new Date()).format("YYYY-MM-DD")}`
  }),

  components: { Datatables, FilterInput },
  computed: {
    dateNow() {
      return moment(Date.now()).format("YYYY-MM-DD");
    }
  },
  methods: {
    // handleDate({ val }) {
    //   this.date = val;
    //   console.log(val, "1111111111");
    //   this.url = `/syn/homeisolation/dashboard?date=${this.date}`;
    //   this.$eventBus.$emit("reload-datatable");
    // },
    async handleClick({ date }) {
      // this.name = name;
      // this.statusSelected = selected;
      this.url = `/syn/homeisolation/dashboard?date=${date ? date : ""}`;
      // console.log("ค้นหา");
    }
  }
};
</script>

<style scoped></style>
