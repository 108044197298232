import { render, staticRenderFns } from "./date-custom-homeiregis.vue?vue&type=template&id=5abe16fd&"
import script from "./date-custom-homeiregis.vue?vue&type=script&lang=js&"
export * from "./date-custom-homeiregis.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports