export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "เลขที่งาน", value: "paymentOrderId" },
  { text: "ชื่อหน่วยงาน", value: "agencyName" },
  { text: "oneID", value: "donorId" },
  { text: "ชื่อผู้บริจาค", value: "donorName", componentName: "report-payment-donorname" },
  { text: "วัน-เวลาที่บริจาค", value: "createTime", componentName: "date-custom" },
  { text: "จำนวนเงินที่บริจาค", value: "paymentTotal" },
  { text: "แต้ม", value: "point" },
  { text: "สถานะ", value: "status", componentName: "report-payment-status" }
];
