export const GET_API = "GET_API";

export const GET_AGENCIES_SUCCESS = "GET_AGENCIES_SUCCESS";
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";

export const GET_FAILURE = "GET_FAILURE";

export const GET_AGENCIES_AMOUNT_SUCCESS = "GET_AGENCIES_AMOUNT_SUCCESS";

export const GET_AGENCIES_HOMEI_SUCCESS = "GET_AGENCIES_HOMEI_SUCCESS";
