<template>
  <i-card
    ><v-checkbox
      class="i-checkbox"
      v-model="localCheck"
      label="ส่งข้อความ"
      style="margin-top: 0"
    ></v-checkbox>
    <div v-if="localCheck">
      <i-text-area v-model="messageTxt" label="ข้อความ"></i-text-area>
      <v-btn
        outlined
        color="#1E4D94"
        style="text-transform: none; margin-right: 10px"
        @click="handleSubmit"
        :disabled="!messageTxt || userList.length == 0"
        >ส่งข้อความ</v-btn
      >
    </div>
  </i-card>
</template>

<script>
import axios from "clients/axios";
export default {
  data: () => ({
    check: false,
    messageTxt: ""
  }),
  props: {
    userList: {
      default: () => []
    },
    value: {}
  },
  computed: {
    localCheck: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    async handleSubmit() {
      await axios.post(`/locallifebot/broadcastmessage`, {
        message: this.messageTxt,
        to: this.userList
      });
      this.$dialog({
        // title: "Error",
        showCancel: false,
        type: "success",
        desc: "ส่งข้อความเรียบร้อยแล้ว"
      });
    }
  }
};
</script>

<style scoped>
.card-list-container {
  padding: 20px 0 0 0;
}
</style>
