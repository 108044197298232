<template>
  <div>{{ first_name_th }} {{ last_name_th }}</div>
</template>

<script>
export default {
  props: ["first_name_th", "last_name_th"]
};
</script>

<style></style>
