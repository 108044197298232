export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อคูปอง", value: "couponName" },
  { text: "refId", value: "refId" },
  { text: "คำนำหน้าชื่อ", value: "title" },
  { text: "ชื่อ", value: "firstName" },
  { text: "สกุล", value: "lastName" },
  { text: "รหัสนักศึกษา", value: "studentId" },
  { text: "เบอร์โทร", value: "phone" },
  { text: "อีเมล", value: "email" },
  { text: "ผู้รับผิดชอบ", value: "owner" },
  { text: "วัน-เวลา", value: "createTime", componentName: "date-custom" },
  { text: "สถานะ", value: "status", componentName: "status-custom" },
  { text: "หมายเหตุ", value: "remark" }
];
