<template>
  <div>
    <div class="foundation-action-container">
      <v-switch v-model="switch_status" inset :label="`${Text}`"></v-switch>
    </div>
  </div>
</template>

<script>
// import FoundationEditable from "components/dialog/foundation-editable.vue";
import axios from "clients/axios";
export default {
  props: ["id", "name", "admin", "status"],
  components: {
    // FoundationEditable
  },
  data: () => ({
    // dialog: false,
    // switch2: false
  }),
  computed: {
    switch_status: {
      get() {
        switch (this.status) {
          case "active":
            return true;
          case "inacitve":
            return false;
          default:
            return false;
        }
      },
      set(val) {
        console.log(val);
        if (val == true) {
          console.log("active");
          this.cheangStatusActive();
        } else {
          console.log("inactive");
          this.cheangStatusInactive();
        }
        // await axios.put(`/donate-coupon/activecoupondonate/${this.id}`, {
        //   status: val ? "active" : "inactive"
        // });
        // this.$eventBus.$emit("reload-datatable");
      }
    },
    Text() {
      switch (this.status) {
        case null:
          return "ไม่เปิดใช้งาน";
        case "active":
          return "เปิดใช้งาน";
        case "inactive":
          return "ไม่เปิดใช้งาน";
        default:
          return "";
      }
    }
  },
  watch: {},
  methods: {
    cheangStatusActive() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc: "คุณต้องการเปลี่ยนสถานะหน่วยงานนี้หรือไม่",
        icon: "mdi-help-circle-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ตกลง",
        width: 450,
        onOk: async () => {
          await axios.get(`/agency/isolate/status/${this.id}?status=active`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้เปลี่ยนสถานะหน่วยงานเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        },
        onCancel: async () => {
          // console.log("ojj");
          this.$eventBus.$emit("reload-datatable");
        }
      });
    },
    cheangStatusInactive() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc: "คุณต้องการเปลี่ยนสถานะหน่วยงานนี้หรือไม่",
        icon: "mdi-help-circle-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ตกลง",
        width: 450,
        onOk: async () => {
          await axios.get(`/agency/isolate/status/${this.id}?status=inactive`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้เปลี่ยนสถานะหน่วยงานเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        },
        onCancel: async () => {
          console.log("ojj");
          this.$eventBus.$emit("reload-datatable");
        }
      });
    }
  }
};
</script>

<style scoped>
.foundation-action-container {
  display: flex;
  justify-content: space-around;
  justify-content: start;
}
</style>
