<template>
  <div>
    <i-header title="Import คูปอง"><div></div></i-header>

    <FilterInput
      @on-click="handleClick"
      :selectItems="status"
      nameLabel="ค้นหารายละเอียดคูปอง"
      nameInputLabel="ค้นหารายละเอียดคูปอง"
      :hasDate="true"
    />

    <Datatables :columns="columns" :url="url" />
  </div>
</template>

<script>
import moment from "moment";
import { columns } from "data/tables/importcoupon-history-colum";
import Datatables from "components/datatables/index.vue";
import FilterInput from "components/filter-input/index.vue";
import { status } from "data/status-agency";

export default {
  data: () => ({
    columns,
    status,
    name: "",
    statusSelected: "",
    url: `/coupon/getlistcoupon?startDate=${moment(Date.now()).format(
      "YYYY-MM-DD"
    )}&endDate=&status=&refId=&search=`
  }),
  components: { Datatables, FilterInput },
  computed: {
    dateNow() {
      return moment(Date.now()).format("YYYY-MM-DD");
    }
  },
  methods: {
    async handleClick({ name, selected, dateRange }) {
      this.name = name;
      this.statusSelected = selected;
      console.log(dateRange);
      this.url = await `/coupon/getlistcoupon?startDate=${
        dateRange[0] ? dateRange[0] : ""
      }&endDate=${dateRange[1] ? dateRange[1] : ""}&status=${
        selected ? selected : ""
      }&refId=&search=${name}`;
    }
  }
};
</script>

<style scoped></style>
