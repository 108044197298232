<template>
  <div id="app">
    <v-app style="font-family: 'Prompt', sans-serif !important">
      <component :is="layout">
        <router-view />
      </component>
    </v-app>
  </div>
</template>

<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || "main-layout";
    }
  }
};
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
.detail-action {
  margin-top: 20px;
  padding: 0 50px;
}
</style>
