<template>
  <div>
    <div class="foundation-action-container">
      <v-btn
        icon
        class="btn-reject"
        style="margin-right: 15px"
        @click="handleDelete"
        :disabled="canEdit"
      >
        <v-icon color="red">mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
    <!-- <coupon-isolate-editable v-model="dialog" :isCreate="false" :data="data" /> -->
  </div>
</template>

<script>
// import CouponIsolateEditable from "components/dialog/coupon-isolate-editable.vue";
// import moment from "moment";
import axios from "clients/axios";
export default {
  props: ["id", "status"],
  components: {
    // CouponIsolateEditable
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    data() {
      return {};
    },
    canEdit() {
      return "approve" && false;
    }
  },
  methods: {
    handleDetail() {
      this.$router.push(`/isulation/coupon/${this.id}`);
    },
    handleDelete() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc: "คุณต้องการลบรายชื่อนี้หรือไม่",
        icon: "mdi-trash-can-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ลบรายชื่อ",
        width: 450,
        onOk: async () => {
          await axios.delete(`/coupon/homeisolate/import/${this.id}`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้ลบรายชื่อเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.foundation-action-container {
  display: flex;
  justify-content: space-around;
}
</style>
