import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (localStorage.getItem("access_token") || to.path === "/login") {
    next();
  } else {
    next("/login");
  }
});

export default router;
