<template>
  <div>{{ dataTimeFormat }}</div>
</template>

<script>
import moment from "moment";
export default {
  props: ["start", "end"],
  computed: {
    dataTimeFormat() {
      return `${this.start ? moment(this.start).format("DD/MM/YYYY") : ""} - ${
        this.end ? moment(this.end).format("DD/MM/YYYY") : ""
      }`;
    }
  }
};
</script>

<style></style>
