export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อโครงการ", value: "name" },
  { text: "ชื่อหน่วยงาน", value: "agencyName" },
  { text: "วันที่ยื่นคำร้อง", value: "createTime", componentName: "date-custom" },
  { text: "สถานะ", value: "status", componentName: "status-custom" },
  { text: "ดูข้อมูล", value: "see-detail", componentName: "see-detail" }
];
