<template>
  <div>
    <i-header title="Import คูปอง"><div></div></i-header>
    <v-form ref="form" v-model="valid" lazy-validation>
      <i-card class="coupon-detail">
        <div>
          <div class="input-title">วันที่</div>
          <div class="form-input">
            <DateTime v-model="date"></DateTime>
          </div>
        </div>
        <div>
          <div>ชื่อคูปอง :</div>
          <i-select-ss
            v-model="localsynCoupon"
            label="เลือกคูปอง"
            :url="url"
            item-text="couponName"
            item-value="couponId"
            @text-selected="textSelected"
          ></i-select-ss>
        </div>
        <div>
          <div>refId :</div>
          <i-text-field
            :disabled="isDisabled"
            v-model="localsynCoupon"
            :rules="[v => !!v || 'ระบุเลขอ้างอิงคูปอง']"
            label="เลขอ้างอิงคูปอง"
          ></i-text-field>
        </div>
        <!-- <div>
          <div class="input-title">เลขอ้างอิงคูปอง</div>
          <div>
            <i-text-field v-model="refId" label="เลขอ้างอิงคูปอง" :rules="refRules"></i-text-field>
          </div>
        </div>
        <div>
          <div class="input-title">ชื่อคูปอง</div>
          <i-text-field v-model="couponName" label="ชื่อคูปอง" :rules="nameRules"></i-text-field>
        </div> -->
        <div>
          <div class="input-title">จำนวนคูปองต่อคน</div>
          <i-text-field
            v-model="amount"
            label="จำนวน"
            :rules="amountRules"
            type="number"
          ></i-text-field>
        </div>
      </i-card>
      <br />
      <i-card mt-3>
        <div class="xcel-content">
          <div class="image-content">
            <div>
              <v-btn text color="#1E4D94" @click="$refs.fileInput.click()">
                <v-icon> mdi-plus </v-icon>เพิ่มไฟล์
              </v-btn>
              {{ fileName }}
              <div class="file-alert">{{ this.fileEmty }}</div>
            </div>
          </div>
          <input
            ref="fileInput"
            @change="fileChange"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            class="image-input"
          />
          <a :href="urlTemplate" class="ml-10">Template</a>
        </div>

        <div class="action-button mt-3">
          <i-button @click="handleSubmit">แจกคูปอง</i-button>
        </div>
      </i-card>
    </v-form>
    <br />

    <v-tabs v-model="tab">
      <v-tab v-for="item in tabs" :key="item.key">{{ item.value }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabs" :key="item.key">
        <Datatables
          v-if="item.key == 'success'"
          :columns="columns"
          :notGetData="true"
          :dataFromProp="sucsessData"
        />
        <Datatables v-else :columns="columns" :notGetData="true" :dataFromProp="failData" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "clients/axios";
import { columns } from "data/tables/importcoupon-colum";
import Datatables from "components/datatables/index.vue";
import DateTime from "components/inputs/date";
import moment from "moment";
export default {
  data: () => ({
    columns,
    localsynCoupon: null,
    date: null,
    valid: true,
    file: null,
    // refId: null,
    amount: null,
    // couponName: null,
    imageIcon: require("assets/image/picture.svg"),
    fileName: "",
    fileEmty: "",
    failData: [],
    sucsessData: [],
    tab: "sucsess",
    tabs: [
      {
        value: "สำเร็จ",
        key: "success"
      },
      {
        value: "ผิดพลาด",
        key: "failure"
      }
    ],
    urlTemplate: `${process.env.VUE_APP_BASE_URL_API}/coupon/template`
  }),
  components: { Datatables, DateTime },
  watch: {
    // data: {
    //   handler(val) {
    //     this.date = val.day;
    //     this.localsynCoupon = val.refId;
    //   },
    //   immediate: true
    // },
    date: {
      handler(val) {
        this.day = val;
      },
      immediate: true
    },
    localsynCoupon(val) {
      this.refId = val;
      console.log("localsynCoupon", val);
    }
  },
  computed: {
    url() {
      console.log("this.day", this.dateformat);
      return `/coupon/getcouponsynhomeisolate/${this.dateformat}`;
    },
    dateformat() {
      return moment(this.date).format("YYYY-MM-DD");
    },
    isDisabled() {
      return true;
    },
    refRules() {
      return [v => !!v || "กรุณากรอกเลขอ้างอิงคูปอง"];
    },
    nameRules() {
      return [v => !!v || "กรุณากรอกชื่อคูปอง"];
    },
    amountRules() {
      return [v => !!v || "กรุณากรอกจำนวน"];
    },
    body() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("amount", this.amount);
      formData.append("refId", this.refId);
      formData.append("couponName", this.couponName);
      return formData;
    }
  },
  methods: {
    fileChange(e) {
      // let URL = window.URL || window.webkitURL;
      this.file = e.target.files[0];
      if (this.file) {
        this.fileEmty = "";
        this.fileName = this.file.name;
      }
    },
    async handleSubmit() {
      if (!this.file) {
        this.fileEmty = "กรุณาเลือกไฟล์";
      }
      if (this.$refs.form.validate() && this.file) {
        const res = await axios.post("coupon/importcoupon", this.body);
        console.log(res.data);
        if (res.data.status) {
          this.sucsessData = res.data.data.success;
          this.failData = res.data.data.fail;
          this.file = null;
          this.fileName = "";
          this.$refs.fileInput.value = null;
        }
      }
    },
    dateInput(value) {
      this.data.day = value;
      console.log(this.data);
    },
    textSelected(val) {
      console.log("textddd", val);
      this.couponName = val;
    }
  }
};
</script>

<style scoped>
.xcel-content {
  display: flex;
  align-items: center;
}
.coupon-detail {
  display: block;
}
.input-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Color / Neutral / #333333 */

  color: #333333;

  margin-bottom: 10px;
}
.image-input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  opacity: 0;
  cursor: pointer;
  display: none;
}
.image-content {
  display: flex;
  flex-direction: column;
}
.file-alert {
  color: red;
}
</style>
