<template>
  <div>{{ dataTimeFormat }}</div>
</template>

<script>
import moment from "moment";
export default {
  props: ["expireTime", "usageStartTime", "header"],
  computed: {
    dataTimeFormat() {
      if (this.header.isstart) {
        return this.usageStartTime ? moment(this.usageStartTime).format("DD/MM/YYYY") : "ไม่ระบุ";
      }
      return this.expireTime ? moment(this.expireTime).format("DD/MM/YYYY") : "ไม่มีวันสิ้นสุด";
    }
  }
};
</script>

<style></style>
