<template>
  <div>
    <div class="input-title">{{ item.label }}</div>
    <div>
      <i-text-field
        v-if="item.type === 'text'"
        v-model="localValue"
        :rules="item.rules"
        :label="item.inputLabel || item.label"
        :is-viewing="isViewing"
      ></i-text-field>
      <i-text-area
        v-if="item.type === 'desc'"
        v-model="localValue"
        :rules="item.rules"
        :label="item.inputLabel || item.label"
        :is-viewing="isViewing"
      ></i-text-area>
      <i-select
        v-if="item.type === 'select'"
        v-model="localValue"
        :rules="item.rules"
        :label="item.inputLabel || item.label"
        :items="item.items"
        :is-viewing="isViewing"
      ></i-select>
      <i-select-ss
        v-if="item.type === 'select-ss'"
        v-model="localValue"
        :rules="item.rules"
        :label="item.inputLabel || item.label"
        :url="item.url"
        :item-text="item.itemText"
        :is-viewing="isViewing"
      ></i-select-ss>
      <date-range
        v-if="item.type === 'date-range'"
        v-model="localValue"
        :start-date="model[item.firstInputKey]"
        :end-date="model[item.secondInputKey]"
        @input-start-date="val => $emit('first-input', val)"
        @input-end-date="val => $emit('second-input', val)"
        :rules="item.rules"
        :label="item.inputLabel || item.label"
        :url="item.url"
        :item-text="item.itemText"
        :is-viewing="isViewing"
      ></date-range>

      <v-checkbox
        v-if="item.type === 'checkbox'"
        :disabled="isViewing"
        class="i-checkbox"
        v-model="localValue"
        :label="item.label"
        style="margin-top: 0"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import DateRange from "components/inputs/date-range";
export default {
  props: ["item", "model", "value", "isViewing"],
  components: {
    DateRange
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped>
.input-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Color / Neutral / #333333 */

  color: #333333;

  margin-bottom: 10px;
}
</style>

<style>
.text-view {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin: 16px;
}
</style>
