<template>
  <v-dialog v-model="dialog_date_time" persistent max-width="530px" scrollable style="">
    <v-card flat tile class="pa-0" style="overflow: hidden;">
      <v-card-subtitle class="px-0">
        <v-app-bar flat class="color_gradient_primary">
          <v-row align="center" justify="center">
            <v-col cols="12" align="center">
              <h3 class="my-3 font-weight-regular white--text">เลือกวันที่</h3>
            </v-col>
          </v-row>
        </v-app-bar>
      </v-card-subtitle>
      <v-card-text class="pt-1">
        <v-row align="center">
          <!-- keep -->
          <v-col align="center" v-if="this.type_time == 'start_date_keep'">
            <v-date-picker
              v-model="date"
              @input="menu = false"
              :reactive="reactive"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              color="primary"
              elevation="2"
              no-title
              locale="th"
              :min="now_date"
              :max="end_keep"
              full-width
            ></v-date-picker>
          </v-col>

          <v-col align="center" v-else-if="this.type_time == 'end_date_keep'">
            <v-date-picker
              v-model="date"
              @input="menu = false"
              :reactive="reactive"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              color="primary"
              elevation="2"
              no-title
              locale="th"
              :min="start_keep"
              full-width
            ></v-date-picker>
          </v-col>

          <!-- use -->

          <v-col align="center" v-if="this.type_time == 'start_date_use'">
            <v-date-picker
              v-model="date"
              @input="menu = false"
              :reactive="reactive"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              color="primary"
              elevation="2"
              no-title
              locale="th"
              :min="check_keep_and_now_date"
              :max="end_use"
              full-width
            ></v-date-picker>
          </v-col>

          <v-col align="center" v-else-if="this.type_time == 'end_date_use'">
            <v-date-picker
              v-model="date"
              @input="menu = false"
              :reactive="reactive"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              color="primary"
              elevation="2"
              no-title
              locale="th"
              :min="start_use"
              full-width
            ></v-date-picker>
          </v-col>
        </v-row>
        <!-- เวลาา -->
        <v-row class="px-5">
          <v-col class="py-0" cols="12" align="left">
            <v-checkbox
              v-model="time"
              label="เวลา"
              style="margin-top: 0px; width: 10%;"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row class="px-8" v-if="time == true">
          <v-col class="py-0 pr-0" cols="5" align="center">
            <a-time-picker
              format="HH"
              style="width: 100%;font-family: 'Prompt';"
              inputReadOnly
              placeholder="เลือกชั่วโมง"
              clearText
              @change="onChange_hh"
              :default-value="moment(hh_time_edit(), 'HH')"
              :disabledHours="disabledHours"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
            </a-time-picker>
          </v-col>
          <v-col cols="2" class="px-0 py-2" align="center">
            <p>-</p>
          </v-col>
          <v-col class="py-0 pl-0" cols="5" align="center">
            <a-time-picker
              format="mm"
              style="width: 100%;font-family: 'Prompt';"
              @change="onChange_mm"
              inputReadOnly
              placeholder="เลือกนาที"
              :minute-step="1"
              :disabledMinutes="disabledMinutes"
              :default-value="moment(mm_time_edit(), 'mm')"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
            </a-time-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="py-1 mx-0"></v-divider>
      <v-card-actions>
        <v-row class="py-2 px-10" align="center" justify="center">
          <v-col align="center" cols="6">
            <v-btn class="primary--text" outlined block @click="closeDialog()">ยกเลิก</v-btn>
          </v-col>
          <v-col align="center" cols="6">
            <v-btn
              class="white--text color_gradient_primary "
              block
              :disabled="date == null"
              @click="btn_submit()"
              >บันทึก</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";

export default {
  props: {
    prop_time_period: {
      type: Object,
      require: true,
      default() {}
    },
    prop_date_for_mode_edit: {
      type: Object,
      require: true,
      default() {}
    },
    mode: {
      type: Object,
      require: true,
      default() {}
    }
  },

  data: vm => ({
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu: false,
    now_date: new Date().toISOString().slice(0, 10),

    date_test: new Date(),
    reactive: false,
    dialog_date_time: false,
    time: false,
    type_time: "",

    value_starttime: null,
    value_endtime: null,
    end_time: null,
    date_not_convert_select: null,
    date_convert_select: "",
    //time
    time_hour: "",
    time_min: "",
    data_dateNow: ""
  }),
  computed: {
    computedDateFormatted() {
      // console.log("date",this.date);
      if (this.type_time == "end_date_keep") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.prop_date_for_mode_edit.end_keep = this.date;
        if (this.date > this.start_keep) {
          // console.log("555");
          return this.formatDate(this.date);
        } else {
          // console.log("object", this.start_keep, this.date);
          return this.formatDate(this.start_keep);
        }
      } else if (this.type_time == "end_date_use") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.prop_date_for_mode_edit.end_use = this.date;
        if (this.date > this.start_use) {
          return this.formatDate(this.date);
        } else {
          return this.formatDate(this.start_use);
        }
      }
      return this.formatDate(this.date);
    },
    start_keep() {
      if (this.prop_time_period.start_date_keep != null) {
        var date = this.prop_time_period.start_date_keep.slice(0, 2);
        var month = this.prop_time_period.start_date_keep.slice(3, 5);
        var year = this.prop_time_period.start_date_keep.slice(6, 10);
        let min = year + "-" + month + "-" + date;
        // console.log(new Date(min)>new Date())
        if (new Date(min) > new Date()) {
          return min;
        } else {
          return this.now_date;
        }
      } else {
        return this.now_date;
      }
    },
    end_keep() {
      if (this.prop_time_period.end_date_keep != null) {
        let date = this.prop_time_period.end_date_keep.slice(0, 2);
        let month = this.prop_time_period.end_date_keep.slice(3, 5);
        let year = this.prop_time_period.end_date_keep.slice(6, 10);
        // console.log("5555");
        return year + "-" + month + "-" + date;
      } else if (this.prop_time_period.start_date_use != null) {
        let date = this.prop_time_period.start_date_use.slice(0, 2);
        let month = this.prop_time_period.start_date_use.slice(3, 5);
        let year = this.prop_time_period.start_date_use.slice(6, 10);
        // console.log("55554");
        return year + "-" + month + "-" + date;
      }
      return "";
    },
    check_keep_and_now_date() {
      if (this.prop_time_period.start_date_keep != null) {
        var date = this.prop_time_period.start_date_keep.slice(0, 2);
        var month = this.prop_time_period.start_date_keep.slice(3, 5);
        var year = this.prop_time_period.start_date_keep.slice(6, 10);
        return year + "-" + month + "-" + date;
      } else {
        return this.now_date;
      }
    },
    start_use() {
      if (this.prop_time_period.start_date_use != null) {
        var date = this.prop_time_period.start_date_use.slice(0, 2);
        var month = this.prop_time_period.start_date_use.slice(3, 5);
        var year = this.prop_time_period.start_date_use.slice(6, 10);
        let min_use = year + "-" + month + "-" + date;
        if (new Date(min_use) > new Date()) {
          return min_use;
        } else {
          return this.now_date;
        }
      } else {
        return this.now_date;
      }
    },
    end_use() {
      if (this.prop_time_period.end_date_use != null) {
        let date = this.prop_time_period.end_date_use.slice(0, 2);
        let month = this.prop_time_period.end_date_use.slice(3, 5);
        let year = this.prop_time_period.end_date_use.slice(6, 10);
        return year + "-" + month + "-" + date;
      }
      return null;
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    }
  },
  created() {
    // console.log("+++",this.prop_time_period);
    // console.log(this.prop_date_for_mode_edit);
  },
  methods: {
    open(type) {
      // console.log(type);
      this.setDateForCreateEditMode(type);
      this.type_time = type;
      this.dialog_date_time = true;
    },
    hh_time_edit() {
      let timeNow = new Date();
      let hh = timeNow.getHours();

      if (this.type_time == "end_date_keep" && this.mode.mode == "EDIT") {
        // console.log("hh-1---");
        if (this.prop_time_period.hour_end_keep == "") {
          return hh;
        } else {
          return this.prop_time_period.hour_end_keep;
        }
      } else if (this.type_time == "end_date_use" && this.mode.mode == "EDIT") {
        // console.log("hh-2---");
        // return this.prop_time_period.hour_end_use;
        if (this.prop_time_period.hour_end_use == "") {
          return hh;
        } else {
          return this.prop_time_period.hour_end_use;
        }
      } else {
        // console.log("hh-3---");
        return hh;
      }
    },
    mm_time_edit() {
      let timeNow = new Date();
      let mm = timeNow.getMinutes();

      if (this.type_time == "end_date_keep" && this.mode.mode == "EDIT") {
        // console.log("mm-1---");
        if (this.prop_time_period.min_end_keep == "") {
          return mm;
        } else {
          return this.prop_time_period.min_end_keep;
        }
      } else if (this.type_time == "end_date_use" && this.mode.mode == "EDIT") {
        // console.log("mm-2---");
        // return this.prop_time_period.min_end_use;
        if (this.prop_time_period.min_end_use == "") {
          return mm;
        } else {
          return this.prop_time_period.min_end_use;
        }
      } else {
        // console.log("mm-3---");
        return mm;
      }
    },
    setDateForCreateEditMode(type) {
      // console.log(type);
      // console.log(this.date);
      // console.log(this.prop_date_for_mode_edit);
      if (this.mode.mode == "EDIT") {
        //date
        if (type == "end_date_keep" && this.prop_date_for_mode_edit.end_keep != null) {
          this.date = this.prop_date_for_mode_edit.end_keep.slice(0, 10);
          this.time_hour = this.prop_time_period.hour_end_keep;
          this.time_min = this.prop_time_period.min_end_keep;
        } else if (type == "end_date_use" && this.prop_date_for_mode_edit.end_use != null) {
          this.date = this.prop_date_for_mode_edit.end_use.slice(0, 10);
          this.time_hour = this.prop_time_period.hour_end_use;
          this.time_min = this.prop_time_period.min_end_use;
        }
        //time
        this.time = true;
        // this.time_hour = "10";
      } else if (this.mode.mode == "CREATE") {
        // console.log("create====");

        if (type == "start_date_keep" && this.prop_time_period.start_date_keep != null) {
          this.date =
            this.prop_time_period.start_date_keep.slice(6, 10) +
            "-" +
            this.prop_time_period.start_date_keep.slice(3, 5) +
            "-" +
            this.prop_time_period.start_date_keep.slice(0, 2);

          this.time = true;
        } else if (type == "end_date_keep" && this.prop_time_period.end_date_keep != null) {
          this.date =
            this.prop_time_period.end_date_keep.slice(6, 10) +
            "-" +
            this.prop_time_period.end_date_keep.slice(3, 5) +
            "-" +
            this.prop_time_period.end_date_keep.slice(0, 2);

          this.time = true;
        } else if (type == "start_date_use" && this.prop_time_period.start_date_use != null) {
          this.date =
            this.prop_time_period.start_date_use.slice(6, 10) +
            "-" +
            this.prop_time_period.start_date_use.slice(3, 5) +
            "-" +
            this.prop_time_period.start_date_use.slice(0, 2);

          this.time = true;
        } else if (type == "end_date_use" && this.prop_time_period.end_date_use != null) {
          this.date =
            this.prop_time_period.end_date_use.slice(6, 10) +
            "-" +
            this.prop_time_period.end_date_use.slice(3, 5) +
            "-" +
            this.prop_time_period.end_date_use.slice(0, 2);

          this.time = true;
        } else {
          this.date = new Date().toISOString().substr(0, 10);
        }
      } else {
        // console.log("else====");
        let timeNow = new Date();

        this.time = false;
        this.time_hour = timeNow.getHours();
        this.time_min = timeNow.getMinutes();
        this.value_starttime = "";
      }
    },
    closeDialog() {
      this.time = false;
      this.date = null;
      this.dialog_date_time = false;
    },
    moment,
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },
    //time
    onChange() {
      // console.log(time, timeString);
    },

    onChange_hh(time, timeString) {
      this.value_starttime = time;
      this.time_hour = timeString;
    },
    onChange_mm(time, timeString) {
      // console.log(time, timeString);
      this.value_endtime = time;
      this.time_min = timeString;
    },
    getDateNow() {
      let timeNow = new Date();
      var dd = "";
      var mm = "";

      switch (true) {
        case timeNow.getDate() < 10 && timeNow.getMonth() + 1 < 10:
          dd = "0" + timeNow.getDate();
          mm = "0" + (timeNow.getMonth() + 1);
          this.data_dateNow = dd + "-" + mm + "-" + timeNow.getFullYear();
          break;
        case timeNow.getDate() < 10 && timeNow.getMonth() + 1 > 10:
          dd = "0" + timeNow.getDate();
          mm = timeNow.getMonth() + 1;
          this.data_dateNow = dd + "-" + mm + "-" + timeNow.getFullYear();
          break;
        case timeNow.getDate() > 10 && timeNow.getMonth() + 1 < 10:
          dd = timeNow.getDate();
          mm = "0" + (timeNow.getMonth() + 1);
          this.data_dateNow = dd + "-" + mm + "-" + timeNow.getFullYear();
          break;
      }
    },
    disabledHours() {
      var hours = [];

      this.getDateNow();
      let dd_now = parseInt(this.data_dateNow.slice(0, 2));
      let dd_select = parseInt(this.dateFormatted.slice(0, 2));
      if (dd_select == dd_now) {
        if (dd_select <= dd_now) {
          for (var i = 0; i < moment().hour(); i++) {
            hours.push(i);
          }
          return hours;
        }
      }
    },
    disabledMinutes() {
      var minutes = [];
      this.getDateNow();
      let dd_now = parseInt(this.data_dateNow.slice(0, 2));
      let dd_select = parseInt(this.dateFormatted.slice(0, 2));
      if (dd_select == dd_now) {
        if (this.time_hour == moment().hour()) {
          for (var i = 0; i < moment().minute(); i++) {
            minutes.push(i);
          }
        }
        return minutes;
      } else {
        return null;
      }
    },
    btn_submit() {
      let timeNow = new Date();
      // console.log(timeNow);
      // console.log("time_min",this.time_min);
      // console.log("time_hour",this.time_hour);
      if (this.time == true) {
        if (this.time_hour == "") {
          if (timeNow.getHours() < 10) {
            this.time_hour = "0" + timeNow.getHours();
          } else {
            this.time_hour = timeNow.getHours();
          }
        }
        if (this.time_min == "") {
          if (timeNow.getMinutes() < 10) {
            this.time_min = "0" + timeNow.getMinutes();
          } else {
            this.time_min = timeNow.getMinutes();
          }
        }

        // console.log(this.time_hour);
        // console.log(":");
        // console.log(this.time_min);

        var form_data = {
          type: this.type_time,
          date: this.computedDateFormatted,
          time: this.time_hour + ":" + this.time_min
        };

        if (this.type_time == "start_date_keep") {
          if (this.prop_time_period.end_date_keep != null) {
            //convert date time select
            let date_time_pick = this.date + " " + this.time_hour + ":" + this.time_min;
            // console.log(date_time_pick);
            let cv_date_time_pick = new Date(date_time_pick);
            // console.log(cv_date_time_pick);

            let end_keep =
              this.prop_time_period.end_date_keep.slice(6, 10) +
              "-" +
              this.prop_time_period.end_date_keep.slice(3, 5) +
              "-" +
              this.prop_time_period.end_date_keep.slice(0, 2) +
              " " +
              this.prop_time_period.end_date_keep.slice(11, 13) +
              ":" +
              this.prop_time_period.end_date_keep.slice(14, 16);

            // console.log(this.prop_time_period.end_date_keep.slice(11, 13));
            // console.log(this.prop_time_period.end_date_keep.slice(14, 16));
            // console.log("end_keep-->", end_keep);
            let cv_end_keep = new Date(end_keep);
            // console.log(cv_end_keep);

            if (cv_date_time_pick > cv_end_keep) {
              this.$swal({
                title: "ไม่สามารถบันทึกได้",
                text: "เนื่องจาก 'เวลา' ของวันเริ่มต้น มากกว่าวันสิ้นสุด",
                width: 400,
                icon: "warning",
                confirmButtonColor: "#3085d6",
                customClass: "swal-wide",
                confirmButtonText: `ตกลง`
              });
            } else {
              this.$emit("dataFormDialogDateTime", form_data);
              this.dialog_date_time = false;
              this.date = null;
            }
          } else {
            this.$emit("dataFormDialogDateTime", form_data);
            this.dialog_date_time = false;
            this.date = null;
          }
        } else if (this.type_time == "end_date_keep") {
          // console.log("type_time",this.type_time);
          if (this.prop_time_period.start_date_keep != null) {
            //convert date time select
            let date_time_pick = this.date + " " + this.time_hour + ":" + this.time_min;
            // console.log(date_time_pick);
            let cv_date_time_pick = new Date(date_time_pick);
            // console.log(cv_date_time_pick);

            let start_keep =
              this.prop_time_period.start_date_keep.slice(6, 10) +
              "-" +
              this.prop_time_period.start_date_keep.slice(3, 5) +
              "-" +
              this.prop_time_period.start_date_keep.slice(0, 2) +
              " " +
              this.prop_time_period.start_date_keep.slice(11, 13) +
              ":" +
              this.prop_time_period.start_date_keep.slice(14, 16);

            let cv_start_keep = new Date(start_keep);
            // console.log(cv_start_keep);

            if (cv_start_keep > cv_date_time_pick) {
              this.$swal({
                title: "ไม่สามารถบันทึกได้",
                text: "เนื่องจาก 'เวลา' ของวันสิ้นสุด น้อยกว่าวันเริ่มต้น",
                width: 400,
                icon: "warning",
                confirmButtonColor: "#3085d6",
                customClass: "swal-wide",
                confirmButtonText: `ตกลง`
              });
            } else {
              this.$emit("dataFormDialogDateTime", form_data);
              this.dialog_date_time = false;
              this.date = null;
            }
          } else {
            this.$emit("dataFormDialogDateTime", form_data);
            this.dialog_date_time = false;
            this.date = null;
          }
        } else if (this.type_time == "start_date_use") {
          if (this.prop_time_period.end_date_use != null) {
            //convert date time select
            let date_time_use = this.date + " " + this.time_hour + ":" + this.time_min;
            // console.log(date_time_pick);
            let cv_date_time_pick = new Date(date_time_use);
            // console.log(cv_date_time_pick);

            let end_use =
              this.prop_time_period.end_date_use.slice(6, 10) +
              "-" +
              this.prop_time_period.end_date_use.slice(3, 5) +
              "-" +
              this.prop_time_period.end_date_use.slice(0, 2) +
              " " +
              this.prop_time_period.end_date_use.slice(11, 13) +
              ":" +
              this.prop_time_period.end_date_use.slice(14, 16);

            let cv_end_use = new Date(end_use);
            // console.log(cv_end_keep);

            if (cv_date_time_pick > cv_end_use) {
              this.$swal({
                title: "ไม่สามารถบันทึกได้",
                text: "เนื่องจาก 'เวลา' ของวันเริ่มต้น มากกว่าวันสิ้นสุด",
                width: 400,
                icon: "warning",
                confirmButtonColor: "#3085d6",
                customClass: "swal-wide",
                confirmButtonText: `ตกลง`
              });
            } else {
              this.$emit("dataFormDialogDateTime", form_data);
              this.dialog_date_time = false;
              this.date = null;
            }
          } else {
            this.$emit("dataFormDialogDateTime", form_data);
            this.dialog_date_time = false;
            this.date = null;
          }
        } else if (this.type_time == "end_date_use") {
          if (this.prop_time_period.start_date_use != null) {
            //convert date time select
            let date_time_pick = this.date + " " + this.time_hour + ":" + this.time_min;
            // console.log(date_time_pick);
            let cv_date_time_pick = new Date(date_time_pick);
            // console.log(cv_date_time_pick);

            let start_use =
              this.prop_time_period.start_date_use.slice(6, 10) +
              "-" +
              this.prop_time_period.start_date_use.slice(3, 5) +
              "-" +
              this.prop_time_period.start_date_use.slice(0, 2) +
              " " +
              this.prop_time_period.start_date_use.slice(11, 13) +
              ":" +
              this.prop_time_period.start_date_use.slice(14, 16);

            let cv_start_use = new Date(start_use);
            // console.log(cv_start_keep);

            if (cv_start_use > cv_date_time_pick) {
              this.$swal({
                title: "ไม่สามารถบันทึกได้",
                text: "เนื่องจาก 'เวลา' ของวันสิ้นสุด น้อยกว่าวันเริ่มต้น",
                width: 400,
                icon: "warning",
                confirmButtonColor: "#3085d6",
                customClass: "swal-wide",
                confirmButtonText: `ตกลง`
              });
            } else {
              this.$emit("dataFormDialogDateTime", form_data);
              this.dialog_date_time = false;
              this.date = null;
            }
          } else {
            this.$emit("dataFormDialogDateTime", form_data);
            this.dialog_date_time = false;
            this.date = null;
          }
        }
      } else {
        // console.log("TYPE", this.type_time);
        let tt;
        if (this.type_time == "start_date_keep") {
          tt = "00" + ":" + "00";
        } else if (this.type_time == "end_date_keep") {
          tt = "23" + ":" + "59";
        } else if (this.type_time == "start_date_use") {
          tt = "00" + ":" + "00";
        } else {
          tt = "23" + ":" + "59";
        }
        // console.log(this.computedDateFormatted);
        // this.prop_time_period.start_date_keep;
        // if (
        //   this.computedDateFormatted > this.prop_time_period.start_date_keep
        // ) {
        //   console.log("มาก");
        // } else {
        //   if (this.prop_time_period.start_date_keep !== null) {
        //     this.computedDateFormatted = this.start_keep;
        //     console.log(this.computedDateFormatted);
        //   } else {
        //     console.log("น่อย");
        //   }
        // }
        let form_data = {
          type: this.type_time,
          date: this.computedDateFormatted,
          time: tt
        };
        // console.log(form_data);
        this.$emit("dataFormDialogDateTime", form_data);
        this.dialog_date_time = false;
        this.date = null;
      }
    }
  }
};
</script>
<style scoped></style>
