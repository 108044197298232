<template>
  <div>
    <div class="filter-input-container">
      <div class="project-input">
        <div class="filter-title">อีเมลผู้ดูแลโครงการ ( อีเมล ONE ID )</div>

        <i-text-field v-model="email" label="เพิ่มอีเมลผู้ดูแลโครงการ"></i-text-field>
      </div>
      <div class="filter-action">
        <i-button @click="addMemberAction" :disabled="!email"> เพิ่ม </i-button>
      </div>
    </div>
    <Datatables :columns="columns" :url="`/agency/listagencymember/${id}`" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Datatables from "components/datatables/index.vue";
import { columns } from "data/tables/member-columns";
export default {
  data() {
    return {
      columns,
      value: null,
      email: null
    };
  },
  props: ["id"],
  mounted() {
    // this.getListRoom();
  },
  computed: {
    ...mapState("member", ["listRoom"])
  },
  methods: {
    ...mapActions("member", ["getListRoom", "addMember", "getListRoomByEmail"]),
    async addMemberAction() {
      const user = await this.getListRoomByEmail(this.email);

      console.log(user, user == false);
      if (user == false) {
        this.$dialog({
          // title: "Error",
          showCancel: false,
          type: "error",
          desc: "ไม่พบ email ที่กรอก"
        });
      } else {
        const payload = {
          body: { accountId: user.one_id, email: user.one_email },
          id: this.id
        };
        const res = await this.addMember(payload);
        if (res.status == true) {
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้เพิ่มผู้ดูแลเรียบร้อยแล้ว"
          }).then(() => {
            this.value = null;
            this.localDialog = false;
            this.$eventBus.$emit("reload-datatable");
          });
        } else {
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "error",
            desc: res.message
          }).then(() => {
            this.localDialog = false;
            this.$eventBus.$emit("reload-datatable");
          });
        }
      }
    }
  },
  components: {
    Datatables
  }
};
</script>

<style scoped>
.filter-input-container {
  display: flex;
  /* flex-wrap: wrap; */
}
.project-input {
  flex: 8;
  padding: 8px;
}
.filter-action {
  flex: 2;
  padding: 8px;
  display: flex;
  align-items: center;
}
</style>
