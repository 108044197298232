<template>
  <div>
    <div class="mt-3">
      <i-card>
        <i-header title="กำหนดระยะเวลาคูปอง"><div></div></i-header>
        <div>
          <v-row class="px-1" justify="center">
            <!-- <pre>{{ prop_time_period }}</pre> -->
            <v-col cols="4" class="pl-0 pb-0">
              <v-dialog
                ref="dialog_start"
                v-model="time_dialog_start"
                :return-value.sync="prop_time_period.start_date_keep"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="prop_time_period.start_date_keep"
                    label="เวลาเริ่มต้น"
                    append-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    :disabled="start_keep"
                    :rules="start_date_keep"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_dialog_start"
                  v-model="prop_time_period.start_date_keep"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="time_dialog_start = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_start.save(prop_time_period.start_date_keep)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <!-- <v-col cols="4" class="pr-0 pb-0">
              <v-text-field
                v-model="prop_time_period.start_date_keep"
                @click="openDialogDateTime('start_date_keep')"
                label="เริ่มต้น"
                readonly
                outlined
                dense
                :disabled="start_keep"
                append-icon="mdi-calendar-week"
              ></v-text-field>
            </v-col> -->
            <v-col cols="1" align="center" class="pt-5 px-0">
              <p>-</p>
            </v-col>
            <!-- <v-col cols="4" class="pl-0 pb-0">
              <v-text-field
                v-model="prop_time_period.end_date_keep"
                v-if="prop_time_period.no_exp_keep == false"
                label="สิ้นสุด"
                :disabled="prop_time_period.start_date_keep == null"
                @click="openDialogDateTime('end_date_keep')"
                readonly
                outlined
                append-icon="mdi-calendar-week"
                dense
              ></v-text-field>
            </v-col> -->
            <v-col cols="4" class="pl-0 pb-0">
              <v-dialog
                ref="dialog_end"
                v-model="time_dialog_end"
                :return-value.sync="prop_time_period.end_date_keep"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="prop_time_period.end_date_keep"
                    label="เวลาสิ้นสุด"
                    append-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    :disabled="prop_time_period.start_date_keep == null"
                    :rules="end_date_keep"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_dialog_end"
                  v-model="prop_time_period.end_date_keep"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="time_dialog_end = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_end.save(prop_time_period.end_date_keep)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="3"> </v-col>
          </v-row>

          <!-- <v-row class="px-1">
            <v-col class="py-0" cols="2" align="center">
              <v-checkbox
                v-model="prop_time_period.no_exp_keep"
                label="ไม่ระบุวันสิ้นสุด"
                style="margin-top: -10px;"
              ></v-checkbox>
            </v-col>
            <v-col cols="10"> </v-col>
          </v-row> -->

          <v-row align="center" justify="center">
            <v-col cols="12" class="pb-0">
              <h5 class="font-weight-regular">
                ระยะเวลาใช้งานคูปอง<span class="red--text"> *</span>
              </h5>
            </v-col>
            <v-col cols="12" class="pt-1 pb-0"> </v-col>
          </v-row>
          <v-row class="px-1" justify="center">
            <!-- <v-col cols="4" class="pr-0 pb-0">
              <v-text-field
                v-model="prop_time_period.start_date_use"
                @click="openDialogDateTime('start_date_use')"
                label="เริ่มต้น"
                readonly
                :disabled="start_use"
                outlined
                dense
                append-icon="mdi-calendar-week"
              ></v-text-field>
            </v-col> -->
            <v-col cols="4" class="pl-0 pb-0">
              <v-dialog
                ref="dialog_start2"
                v-model="time_dialog_start2"
                :return-value.sync="prop_time_period.start_date_use"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="prop_time_period.start_date_use"
                    label="เวลาเริ่มต้น"
                    append-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    :disabled="start_use"
                    :rules="start_date_use"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_dialog_start2"
                  v-model="prop_time_period.start_date_use"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="time_dialog_start2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_start2.save(prop_time_period.start_date_use)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="1" align="center" class="pt-5 px-0">
              <p v-if="prop_time_period.no_exp_use == false">-</p>
            </v-col>
            <!-- <v-col cols="4" class="pl-0 pb-0">
              <v-text-field
                v-model="prop_time_period.end_date_use"
                v-if="prop_time_period.no_exp_use == false"
                @click="openDialogDateTime('end_date_use')"
                label="สิ้นสุด"
                :disabled="prop_time_period.start_date_use == null"
                readonly
                outlined
                append-icon="mdi-calendar-week"
                dense
              ></v-text-field>
            </v-col> -->
            <v-col cols="4" class="pl-0 pb-0">
              <v-dialog
                ref="dialog_end2"
                v-model="time_dialog_end2"
                :return-value.sync="prop_time_period.end_date_use"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="prop_time_period.end_date_use"
                    label="เวลาสิ้นสุด"
                    append-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    :disabled="prop_time_period.start_date_use == null"
                    :rules="end_date_use"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_dialog_end2"
                  v-model="prop_time_period.end_date_use"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="time_dialog_end2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_end2.save(prop_time_period.end_date_use)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="3" class="pb-0"> </v-col>
          </v-row>

          <!-- <v-row class="px-1">
            <v-col class="py-0" cols="2" align="center">
              <v-checkbox
                v-model="prop_time_period.no_exp_use"
                label="ไม่ระบุวันสิ้นสุด"
                style="margin-top: -10px;"
              ></v-checkbox>
            </v-col>
            <v-col cols="10"> </v-col>
          </v-row> -->
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>
import * as rules from "@/javascript/rules.js";
export default {
  props: {
    coupon_type: {
      type: String,
      require: true,
      default: ""
    },
    mode: {
      type: Object,
      require: true,
      default() {}
    },
    prop_time_period: {
      type: Object,
      require: true,
      default() {}
    }
  },
  data() {
    return {
      // timeStart: null,
      // timeEnd: null,
      // menu2: false,
      time_dialog_start: false,
      time_dialog_start2: false,
      time_dialog_end: false,
      time_dialog_end2: false,
      //rules
      start_date_keep: rules.start_date_keep,
      end_date_keep: rules.end_date_keep,
      start_date_use: rules.start_date_use,
      end_date_use: rules.end_date_use
    };
  },
  computed: {
    check_date_keep() {
      return this.prop_time_period.no_exp_keep;
    },
    check_date_use() {
      return this.prop_time_period.no_exp_use;
    },
    start_keep() {
      return this.disabledDate(this.prop_time_period.start_date_keep);
    },
    start_use() {
      return this.disabledDate(this.prop_time_period.start_date_use);
    }
  },
  watch: {
    // check_date_keep(val) {
    //   if (val == true) {
    //     return (this.prop_time_period.end_date_keep = null);
    //   }
    // },
    // check_date_use(val) {
    //   if (val == true) {
    //     return (this.prop_time_period.end_date_use = null);
    //   }
    // }
  },
  methods: {
    disabledDate(value) {
      if (this.mode.mode == "EDIT") {
        let start =
          value.substr(6, 4) +
          "-" +
          value.substr(3, 2) +
          "-" +
          value.substr(0, 2) +
          " " +
          value.substr(11, 2) +
          ":" +
          value.substr(14, 2);
        // console.log("===", start);
        let start_keep = new Date(start);
        let date_now = new Date();
        if (date_now > start_keep) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // openDialogDateTime(type) {
    //   this.$emit("openDialogDateTime", type);
    // },
    // statusModePanels() {
    //   if (this.mode.mode == "EDIT" || this.mode.mode == "NORMAL") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    refDataDateTime(form_data) {
      switch (form_data.type) {
        case "start_date_keep":
          return (this.prop_time_period.start_date_keep =
            form_data.date + " " + form_data.time + " น.");
        case "end_date_keep":
          return (this.prop_time_period.end_date_keep =
            form_data.date + " " + form_data.time + " น.");
        case "start_date_use":
          return (this.prop_time_period.start_date_use =
            form_data.date + " " + form_data.time + " น.");
        case "end_date_use":
          return (this.prop_time_period.end_date_use =
            form_data.date + " " + form_data.time + " น.");
        default:
          return "";
      }
    },
    couponConvertData(date_time) {
      // console.log(date_time)
      if (date_time != null) {
        var date = new Date(date_time);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hh = date.getHours();
        var mm = date.getMinutes();
        //date
        let month_covert = "";
        let day_covert = "";

        if (month < 10) {
          month_covert = "0" + month;
        } else {
          month_covert = month;
        }

        if (day < 10) {
          day_covert = "0" + day;
        } else {
          day_covert = day;
        }
        //time
        let hh_covert = "";
        let mm_covert = "";

        if (hh < 10) {
          hh_covert = "0" + hh;
        } else {
          hh_covert = hh;
        }

        if (mm < 10) {
          mm_covert = "0" + mm;
        } else {
          mm_covert = mm;
        }
        var format_date =
          day_covert + "/" + month_covert + "/" + year + "-" + hh_covert + ":" + mm_covert + ":00";
        return format_date;
      } else {
        return "ไม่ระบุวันที่สิ้นสุด";
      }
    }
  }
};
</script>

<style></style>
