<template>
  <div>
    <div class="foundation-action-container">
      <v-btn icon @click="dialog = true">
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon @click="handleDeleteCoupon">
        <v-icon color="primary">mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
    <EditCouponProject
      v-model="dialog"
      :id="id"
      :couponId="couponId"
      :couponStatus="status"
      :couponPrice="price"
      :isCreate="false"
    />
  </div>
</template>

<script>
// import AddcCouponProject from "components/dialog/add-coupon-project.vue";
import EditCouponProject from "components/dialog/edit-coupon-project.vue";
import axios from "clients/axios";
export default {
  props: ["id", "couponId", "status", "price"],
  components: {
    EditCouponProject
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    // handleDelete() {
    //   this.$dialog({
    //     // title: "Error",
    //     showCancel: true,
    //     desc:
    //       "หากคุณต้องการลบหน่วยงานนี้\nโครงการของคุณที่อยู่ภายใต้หน่วยงานนี้จะถูกลบออกจากระบบ\nคุณต้องการลบหน่วยงานนี้หรือไม่",
    //     icon: "mdi-trash-can-outline",
    //     cancelLabel: "ยกเลิก",
    //     okLabel: "ลบหน่วยงาน",
    //     width: 450,
    //     onOk: async () => {
    //       await axios.delete(`/agency/deleteagency/${this.id}`);
    //       this.$dialog({
    //         // title: "Error",
    //         showCancel: false,
    //         type: "success",
    //         desc: "คุณได้ลบหน่วยงานเรียบร้อยแล้ว"
    //       }).then(() => {
    //         this.$eventBus.$emit("reload-datatable");
    //       });
    //     }
    //   });
    // },
    handleDeleteCoupon() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc:
          "หากคุณต้องการลบคูปองงานนี้\nคูปองของคุณที่อยู่ภายใต้หน่วยงานนี้จะถูกลบออกจากระบบ\nคุณต้องการลบคูปองนี้หรือไม่",
        icon: "mdi-trash-can-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ลบคูปอง",
        width: 450,
        onOk: async () => {
          await axios.delete(`/donate-coupon/deletecoupondonate/${this.id}`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้ลบคูปองเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.foundation-action-container {
  display: flex;
  justify-content: space-around;
}
</style>
