<template>
  <div :style="{ color: statusColor }">{{ typeSting }}</div>
</template>

<script>
export default {
  props: ["type"],
  computed: {
    typeSting() {
      switch (this.type) {
        case "in":
          return "รับแต้ม";
        case "out":
          return "โอนแต้ม";
        default:
          return "";
      }
    },
    statusColor() {
      switch (this.type) {
        case "in":
          return "#4E9F5B";
        case "out":
          return "#D1392B";
        default:
          return "#00A0DA";
      }
    }
  }
};
</script>

<style></style>
