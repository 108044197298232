export const columns = [

  //  { text: "ไอดีหน่วยงาน", value: "agencyId" },
   { text: "หน่วยงาน ", value: "agencyName" },
   { text: "ชื่อคูปอง", value: "templateData.couponDetail.couponName" },
   { text: "จำนวนคูปองที่ใช้ได้", value: "templateData.couponRule.quota" },
   { text: "มื้ออาหาร", value: "meal", componentName: "coupon-meal" },
   { text: "เวลาเก็บ", value: "templateData.couponDate.collectStartDate"},
    { text: "เวลาใช้", value: "templateData.couponDate.useStartDate" },
   { text: "สถานะ", value: "templateData.status",componentName: "coupon-status-template"},
   { text: "", value: "action", componentName: "coupon-template-action" }

];
