import axios from "axios";
import { app } from "../main.js";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_API;

const axiosApiInstance = axios;

axiosApiInstance.interceptors.request.use(
  function(config) {
    console.log(
      config.url,
      `${process.env.VUE_APP_BASE_URL_API}/auth/refreshtoken`,
      config.url !== `${process.env.VUE_APP_BASE_URL_API}/auth/refreshtoken`
    );
    if (config.url !== `${process.env.VUE_APP_BASE_URL_API}/auth/refreshtoken`) {
      app.$loading(true);
    }
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };
    }
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    console.log("IN client error ");
    return Promise.reject(error);
  }
);

// Response interceptor for API calls ทำหลังจากได้ผลรับ
axiosApiInstance.interceptors.response.use(
  response => {
    app.$loading(false);
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      localStorage.getItem("refresh_token")
    ) {
      app.$loading(false);
      console.log(401);
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      // const access_token = "hlleoooooo";
      // console.log("access_token=>" + access_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
      // localStorage.clear();
    } else {
      if (error.config.url !== `${process.env.VUE_APP_BASE_URL_API}/auth/refreshtoken`) {
        console.log(error.response);
        app
          .$dialog({
            // title: "Error",
            showCancel: false,
            type: "error",
            desc: error.response.data
          })
          .then(() => {});
      }
      app.$loading(false);
    }

    return Promise.reject(error);
  }
);

export const instance = config => {
  axiosApiInstance.create(config);
};

export default {
  axiosApiInstance,
  get: (url, config = {}) => {
    return axiosApiInstance({ url, method: "get", ...config });
  },
  delete: (url, config = {}) => {
    return axiosApiInstance({ url, method: "delete", ...config });
  },
  post: (url, data, config = {}) => {
    return axiosApiInstance({ url, method: "post", data, ...config });
  },
  put: (url, data, config = {}) => {
    return axiosApiInstance({ url, method: "put", data, ...config });
  }
};

async function refreshAccessToken() {
  try {
    const response = await axios.post(process.env.VUE_APP_BASE_URL_API + "/auth/refreshtoken", {
      refreshToken: localStorage.getItem("refresh_token")
    });

    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("account_id", response.data.account_id);
    localStorage.setItem("username", response.data.username);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    return response.data.access_token;
  } catch {
    localStorage.clear();
    app.$loading(false);
    app.$router.push("/login");
  }
}
