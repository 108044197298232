<template>
  <div>{{ donarText }}</div>
</template>

<script>
export default {
  props: ["type", "projectName", "showDonorName", "donorName"],
  computed: {
    donarText() {
      if (this.type == "in") {
        return this.projectName;
      }
      return this.donorName;
    }
  }
};
</script>

<style></style>
