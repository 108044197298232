<template>
  <div>
    <i-header title="บริจาค"><div></div></i-header>

    <FilterInput @on-click="handleClick" :filterStatus="false" :hasDate="true" />

    <i-header title="รายละเอียดบริจาค">
      <div class="action-container">
        <v-btn outlined color="#1E4D94" style="text-transform: none" :href="`${urlExport}`"
          >Export file</v-btn
        >
      </div>
    </i-header>
    <Datatables :columns="columns" :url="url" modelKey="paymentId" />
  </div>
  <!-- :href="`${urlExport}?agencyName=${name}&startDate=${this.startDate}&endDate=${this.endDate}`" -->
</template>

<script>
import moment from "moment";
import { columns } from "data/tables/report-payment-columns";
import Datatables from "components/datatables/index.vue";
import FilterInput from "components/filter-input/index.vue";

export default {
  data: () => ({
    columns,
    name: "",
    dateRange: [],
    url: `/payment/getpayment?startDate=${moment(Date.now()).format(
      "YYYY-MM-DD"
    )}&endDate=&agencyName=`,
    // url: `/payment/getpayment?startDate=&endDate=&agencyName=`,
    urlExport: `${process.env.VUE_APP_BASE_URL_API}/payment/exportpaymentreport?startDate=${moment(
      Date.now()
    ).format("YYYY-MM-DD")}&endDate=&agencyName=`
  }),
  components: { Datatables, FilterInput },
  computed: {
    dateNow() {
      return [moment(Date.now()).format("YYYY-MM-DD")];
    }
  },
  methods: {
    async handleClick({ name, dateRange }) {
      this.agencyName = name;
      console.log(dateRange);
      this.url = await `/payment/getpayment?startDate=${dateRange[0] ? dateRange[0] : ""}&endDate=${
        dateRange[1] ? dateRange[1] : ""
      }&agencyName=${name ? name : ""}`;
      this.urlExport = await `${
        process.env.VUE_APP_BASE_URL_API
      }/payment/exportpaymentreport?startDate=${dateRange[0] ? dateRange[0] : ""}&endDate=${
        dateRange[1] ? dateRange[1] : ""
      }&agencyName=${name ? name : ""}`;
    }
  }
};
</script>

<style scoped></style>
