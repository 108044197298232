export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "email ผู้ดูแล", value: "email" },
  { text: "ชื่อ-นามสกุล", value: "first_name_th", componentName: "custom-fullname" },
  { text: "เบอร์โทรศัพท์", value: "phoneNumber" },
  { text: "แต้มผู้ดูแล", value: "points" },
  { text: "สถานะ", value: "status", componentName: "status-custom" },
  { text: "", value: "member-delete", componentName: "member-delete" }
];
