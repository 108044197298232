<template>
  <div>
    <div
      :class="
        `image-upload-container ${imagePreview ? 'none-border' : ''} ${
          valid.error ? 'valid-error' : ''
        }`
      "
    >
      <div v-if="!imagePreview && !value" class="image-content">
        <div>
          <img :src="imageIcon" alt="imageIcon" />
        </div>
        <div>
          <v-btn text color="#1E4D94"> <v-icon> mdi-plus </v-icon>เพิ่มรูปภาพ </v-btn>
        </div>
      </div>
      <div v-else>
        <img
          :src="value && value.url ? value.url : `${baseUrl}/media/image${imagePreview}`"
          alt=""
        />
      </div>
      <input
        ref="fileInput"
        @change="fileChange"
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
        class="image-input"
        v-if="!disabled"
      />
    </div>
    <div v-if="valid.error" class="remark">
      {{ valid.message }}
    </div>
    <div class="remark">
      *หมายเหตุ ขนาดรูปภาพโครงการ 800*300 px
    </div>
  </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_BASE_URL_API;
export default {
  props: ["value", "imagePreview", "disabled", "valid"],
  data: () => ({
    imageIcon: require("assets/image/picture.svg"),
    baseUrl,
    file: null
  }),
  watch: {
    imagePreview: {
      handler(val) {
        if (val) {
          this.valid.error = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    fileChange(e) {
      // let URL = window.URL || window.webkitURL;
      this.file = e.target.files[0];
      this.resizeImage(this.file);

      if (this.file && URL && URL.createObjectURL) {
        let url = URL.createObjectURL(this.file);
        this.resizeImage(url);
        // this.file.url = url;
        // this.$emit("input", this.file);
      }
    },
    resizeImage(url) {
      const image = new Image();
      const vm = this;
      image.onload = function() {
        // Resize image
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 300;
        let width = image.width;
        let height = image.height;
        // if (width > height) {
        //   if (width > MAX_WIDTH) {
        //     height *= MAX_WIDTH / width;
        //     width = MAX_WIDTH;
        //   }
        // } else {
        //   if (height > MAX_HEIGHT) {
        //     width *= MAX_HEIGHT / height;
        //     height = MAX_HEIGHT;
        //   }
        // }
        if (width > MAX_WIDTH) {
          width = MAX_WIDTH;
        }
        if (height > MAX_HEIGHT) {
          height = MAX_HEIGHT;
        }
        // console.log(width);
        // image.width = width;
        // image.height = height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);
        canvas.toBlob(blob => {
          vm.file = blob;
          vm.file.url = canvas.toDataURL(blob.type);
          vm.$emit("input", vm.file);
        });
      };
      image.src = url;
    }
  },
  computed: {
    imageRule() {
      return [v => !!v || "ระบุชื่อหน่วยงาน"];
    }
  }
};
</script>

<style scoped>
.image-upload-container {
  border: 1px dashed #1e4d94;
  box-sizing: border-box;
  border-radius: 6px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  max-width: 800px;
}
.image-upload-container.valid-error {
  border: 2px solid red;
}
.image-upload-container.none-border {
  border: none;
}
.image-input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  opacity: 0;
  cursor: pointer;
}
.image-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.remark {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;

  color: #d1392b;
}
</style>
