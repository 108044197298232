import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import agency from "./modules/agency";
import user from "./modules/user";
import member from "./modules/member";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    agency,
    user,
    member
  }
});
