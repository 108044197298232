export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อหน่วยงาน", value: "name" },
  { text: "วันที่และเวลา", value: "lastUpdateTime", componentName: "date-custom" },
   { text: "สถานะ", value: "status", componentName: "foundation-isolation-status" },
  { text: "", value: "action", componentName: "foundation-isolation-action" }
];
