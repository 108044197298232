export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อหน่วยงาน", value: "agencyName" },
  { text: "ชื่อโครงการ", value: "projectName" },
  { text: "แต้ม", value: "point" },
  { text: "ชื่อผู้โอน", value: "donorName", componentName: "report-donorname" },
  { text: "ชื่อผู้รับ", value: "getter", componentName: "report-gettername" },
  { text: "วัน-เวลา", value: "createTime", componentName: "date-custom" },
  { text: "ประเภท", value: "", componentName: "report-detail-status" }
];
