<template>
  <div>{{ dataTimeFormat }}</div>
</template>

<script>
import moment from "moment";
export default {
  props: ["day"],
  computed: {
    dataTimeFormat() {
      return this.day ? moment(this.day).format("DD/MM/YYYY") : "";
    }
  }
};
</script>

<style></style>
