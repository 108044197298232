<template>
  <div class="dashboard-container">
    <DashboardCard v-for="(item, i) in items" v-bind="item" :key="i" />
  </div>
</template>

<script>
import DashboardCard from "./dashboard-card";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    DashboardCard
  },
  data: () => ({
    items: [
      {
        title: "โครงการทั้งหมด",
        value: 0,
        color: "0, 160, 218"
      },
      {
        title: "โครงการรอยืนยัน",
        value: 0,
        color: "233, 159, 22"
      },
      {
        title: "โครงการยืนยันเรียบร้อย",
        value: 0,
        color: "78, 159, 92"
      },
      {
        title: "โครงการถูกยกเลิก",
        value: 0,
        color: "209, 57, 43"
      }
    ]
  }),
  methods: {
    ...mapActions("agency", ["getProjectAmount"])
  },
  async mounted() {
    await this.getProjectAmount();
    this.items[0].value = this.projectAmount.all ? this.projectAmount.all : 0;
    this.items[1].value = this.projectAmount.waiting ? this.projectAmount.waiting : 0;
    this.items[2].value = this.projectAmount.approve ? this.projectAmount.approve : 0;
    this.items[3].value = this.projectAmount.reject ? this.projectAmount.reject : 0;
  },
  computed: {
    ...mapState("agency", ["projectAmount"])
  }
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  flex-wrap: wrap;
}

@media only screen and (max-width: 678px) {
  .dashboard-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
