<template>
  <i-card>
    <div class="filter-input-container">
      <div class="status-input">
        <div class="filter-title">Template Coupon</div>
        <div>
          <i-select-ss
            v-model="localcAgency"
            label="เลือกหน่วยงาน"
            url="/agency/isolate"
            item-text="name"
          ></i-select-ss>
        </div>
      </div>
      <!-- <div class="filter-date">
        <div class="filter-title">ช่วงเวลา</div>
        <div>
          <i-date-range-history
            v-model="dateRange"
            label="ช่วงเวลา"
            hide-details
          ></i-date-range-history>
        </div>
      </div> -->
    </div>
  </i-card>
</template>

<script>
import moment from "moment";
export default {
  props: {
    // nameLabel: {
    //   default: () => "ค้นหารายชื่อโครงการ"
    // },
    // statusLabel: {
    //   default: () => "สถานะ"
    // },
    // nameInputLabel: {
    //   default: () => "ค้นหารายชื่อโครงการ หรือ หน่วยงาน"
    // },
    // statusInputLabel: {
    //   default: () => "สถานะทั้งหมด"
    // },
    // selectItems: {
    //   default: () => []
    // },
    // itemValue: {},
    // itemText: {},
    // filterStatus: {
    //   default: () => true
    // },
    // hasDate: {
    //   default: () => false
    // },
    agency: {}
  },
  data: () => ({
    selected: null,
    name: "",
    dateRange: [moment(Date.now()).format("YYYY-MM-DD")]
  }),
  computed: {
    localcAgency: {
      get: function() {
        return this.agency;
      },
      set: function(value) {
        this.$emit("handle-agency", value);
      }
    }
  },
  watch: {
    // dateRange: {
    //   handler(val) {
    //     if (val.length == 2 && val[0] > val[1]) {
    //       let tmp = val[0];
    //       val[0] = val[1];
    //       val[1] = tmp;
    //     }
    //     this.$emit("handle-date", [
    //       this.dateRange[0] ? this.dateRange[0] : null,
    //       this.dateRange[1] ? this.dateRange[1] : null
    //     ]);
    //   },
    //   immediate: true
    // }
  },
  methods: {
    handleClick() {
      const { name, selected, dateRange } = this;
      this.$emit("on-click", { name, selected, dateRange });
    },
    save(val) {
      console.log("Date save", val);
    }
  }
};
</script>

<style scoped>
.filter-input-container {
  display: flex;
  /* flex-wrap: wrap; */
}

.status-input {
  flex: 4;
  padding: 8px;
}
.filter-date {
  flex: 3;
  padding: 8px;
}

.filter-action {
  flex: 2;
  padding: 8px;
  display: flex;
}

.filter-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;

  margin-bottom: 6px;
}

@media only screen and (max-width: 678px) {
  .filter-input-container {
    flex-direction: column;
  }
}
</style>
