<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation v-if="canaedit">
      <i-card mt-3>
        <div class="xcel-content">
          <div class="image-content">
            <div>
              <v-btn text color="#1E4D94" @click="$refs.fileInput.click()">
                <v-icon> mdi-plus </v-icon>เพิ่มไฟล์
              </v-btn>
              {{ fileName }}
              <div class="file-alert">{{ this.fileEmty }}</div>
            </div>
          </div>
          <input
            ref="fileInput"
            @change="fileChange"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            class="image-input"
          />
          <a :href="urlTemplate" class="ml-10">Template</a>
        </div>

        <div class="action-button mt-3">
          <i-button @click="handleSubmit">อัพโหลดรายชื่อ</i-button>
        </div>
      </i-card>
    </v-form>
    <Datatables
      :columns="columns"
      :url="`/coupon/homeisolate/importcoupon/${$route.params.id}`"
      :messageBok="true"
    />
  </div>
</template>

<script>
import axios from "clients/axios";
import { columns } from "data/tables/importcoupon-isolation-colum";
import Datatables from "components/datatables/index.vue";
// import moment from "moment";
export default {
  data: () => ({
    columns,
    urlTemplate: `${process.env.VUE_APP_BASE_URL_API}/coupon/template`,
    fileName: "",
    fileEmty: "",
    file: null,
    valid: true,
    coupon: {}
  }),
  computed: {
    body() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("couponId", this.$route.params.id);

      return formData;
    },
    canaedit() {
      return this.coupon.status === "waiting";
    }
  },
  components: {
    Datatables
  },
  mounted() {
    this.getCouponDetail();
    this.columns = this.columns.filter(item => {
      return item.value != "check";
    });
  },
  methods: {
    fileChange(e) {
      // let URL = window.URL || window.webkitURL;
      this.file = e.target.files[0];
      if (this.file) {
        this.fileEmty = "";
        this.fileName = this.file.name;
      }
    },
    async handleSubmit() {
      if (!this.file) {
        this.fileEmty = "กรุณาเลือกไฟล์";
      }
      if (this.$refs.form.validate() && this.file) {
        const res = await axios.post("coupon/homeisolate/importcoupon", this.body);

        if (res.data.status) {
          this.$eventBus.$emit("reload-datatable");
          this.file = null;
          this.fileName = "";
          this.$refs.fileInput.value = null;
          this.sucsessData = res.data.data.success;
          this.failData = res.data.data.fail;
        }
      }
    },
    async getCouponDetail() {
      const res = await axios.get(`coupon/couponhomeisolation/${this.$route.params.id}`);
      console.log(res.data.data);
      if (res.data.status === true) {
        this.coupon = res.data.data;
      }
    }
  }
};
</script>

<style scoped>
.image-input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  opacity: 0;
  cursor: pointer;
  display: none;
}
.xcel-content {
  display: flex;
  align-items: center;
}
.image-content {
  display: flex;
  flex-direction: column;
}
.file-alert {
  color: red;
}
</style>
