<template>
  <i-card>
    <div class="filter-input-container">
      <div class="status-input">
        <div class="filter-title">หน่วยงาน</div>
        <div>
          <i-select-ss
            hide-details
            v-model="selected"
            label="เลือกหน่วยงาน"
            url="/agency/isolate"
            item-text="name"
          ></i-select-ss>
        </div>
      </div>
      <div class="status-input">
        <div class="filter-title">มื้ออาหาร</div>
        <div>
          <v-select
            hide-details
            v-model="meal_select"
            label="เลือกมื้ออาหาร"
            :items="meal"
            item-text="name"
            item-value="value"
            outlined
            dense
          >
          </v-select>
        </div>
      </div>
      <!-- <div class="filter-date" v-if="hasDate">
        <div class="filter-title">วันที่</div>
        <i-date v-model="date" label="วันที่" hide-details></i-date>
      </div> -->
      <!-- <div class="filter-action">
        <i-button @click="handleClick">
          ค้นหา
        </i-button>
      </div> -->
    </div>
  </i-card>
</template>

<script>
import moment from "moment";
export default {
  // props: {
  //   nameLabel: {
  //     default: () => "ค้นหารายชื่อโครงการ"
  //   },
  //   nameInputLabel: {
  //     default: () => "ค้นหารายชื่อโครงการ หรือ หน่วยงาน"
  //   },
  //   hasDate: {
  //     default: () => false
  //   }
  // },
  data: () => ({
    date: moment(Date.now()).format("YYYY-MM-DD"),
    selected: null,
    name: "",
    dateRange: [moment(Date.now()).format("YYYY-MM-DD")],
    meal: [
      {
        name: "มื้อเช้า",
        value: "breakfast"
      },
      {
        name: "มื้อเที่ยง",
        value: "lunch"
      },
      {
        name: "มื้อเย็น",
        value: "dinner"
      }
    ],
    meal_select: "",
    meal_views: null
  }),
  watch: {
    selected(val) {
      this.$emit("selct-data", val);
    },
    meal_select(val) {
      this.$emit("selct-meal", val);
    }
  },
  created() {
    let agency_id_view = this.$route.params.agencyId;
    let meal_view = this.$route.params.meal;
    // console.log(meal_view);
    if (agency_id_view && meal_view) {
      this.selected = agency_id_view;
      this.meal_select = meal_view;
    }
  },
  methods: {
    handleClick() {
      const { selected, meal_select } = this;
      console.log(selected, meal_select);
      // this.$emit("on-click", { name, selected, value });
    },
    clear() {
      if (this.$route.params.meal == null || this.$route.params.meal == "") {
        this.meal_select = "";
      }
      // else {
      //   this.callApi();
      // }
    },
    callApi() {
      // this.$emit("selct-data", this.selected);
      // this.$emit("selct-meal", this.meal_select);
    }
  }
};
</script>

<style scoped>
.filter-input-container {
  display: flex;
  /* flex-wrap: wrap; */
}
.project-input {
  flex: 5;
  padding: 8px;
}

.status-input {
  flex: 2;
  padding: 8px;
}
.filter-date {
  flex: 2;
  padding: 8px;
}

.filter-action {
  flex: 2;
  padding: 8px;
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.filter-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;

  margin-bottom: 6px;
}

@media only screen and (max-width: 678px) {
  .filter-input-container {
    flex-direction: column;
  }
}
</style>
