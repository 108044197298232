<template>
  <div>
    <div class="foundation-action-container">
      <v-btn icon @click="dialog = true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon @click="handleDelete">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
    <foundation-editable v-model="dialog" :id="id" :name="name" :isCreate="false" />
  </div>
</template>

<script>
import FoundationEditable from "components/dialog/foundation-editable.vue";
import axios from "clients/axios";
export default {
  props: ["id", "name"],
  components: {
    FoundationEditable
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    handleDelete() {
      this.$dialog({
        // title: "Error",
        showCancel: true,
        desc:
          "หากคุณต้องการลบหน่วยงานนี้\nโครงการของคุณที่อยู่ภายใต้หน่วยงานนี้จะถูกลบออกจากระบบ\nคุณต้องการลบหน่วยงานนี้หรือไม่",
        icon: "mdi-trash-can-outline",
        cancelLabel: "ยกเลิก",
        okLabel: "ลบหน่วยงาน",
        width: 450,
        onOk: async () => {
          await axios.delete(`/agency/deleteagency/${this.id}`);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้ลบหน่วยงานเรียบร้อยแล้ว"
          }).then(() => {
            this.$eventBus.$emit("reload-datatable");
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.foundation-action-container {
  display: flex;
  justify-content: space-around;
}
</style>
