<template>
  <div>
    <div>
      <div class="date-container">
        <div>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="localValue"
                :label="startDateLabel"
                outlined
                dense
                persistent-hint
                apend-icon="mdi-calendar"
                class="i-text-field"
                v-bind="attrs"
                v-on="on"
                :rules="rules"
                autocomplete="off"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDateMenu"
              no-title
              @input="menu1 = false"
              :min="sDateMin"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    label: {},
    value: {},
    hideDetails: {
      default: () => false
    },
    required: {
      default: () => false
    },
    rules: {},
    type: {
      default: () => "text"
    },
    disabled: {
      default: () => false
    },
    isViewing: {},
    startDateLabel: {
      default: () => "เริ่มต้น"
    },
    startDate: {}
  },
  data: () => ({
    startDateMenu: null,
    menu1: false
  }),
  watch: {
    startDateMenu(val) {
      console.log(val);
      this.$emit("input", val);
    },
    value(val) {
      console.log(val);
      this.startDateMenu = val;
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value ? moment(this.value).format("DD/MM/YYYY") : "";
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    sDateMax() {
      return this.endDate;
    },
    sDateMin() {
      // let day = new Date();
      // day.setDate(day.getDate() + 1);
      return null;
    },
    roleEndDate() {
      return [
        v => {
          console.log(!!v);
          if (!this.checkbox && !v) {
            console.log(!!v);
            return "กรุณาระบุวันสิ้นสุดโครงการ";
          }
          return true;
        }
      ];
    }
  }
};
</script>

<style scoped>
.date-container {
  display: flex;
}
.minus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px 20px;
}
</style>

<style>
.i-checkbox.v-input--checkbox .v-input__slot .v-icon {
  color: #757575;
}
.i-checkbox.v-input--checkbox .v-input__slot .v-label {
  color: #757575;
}
</style>
