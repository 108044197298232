export const columns = [
  {
    text: "ลำดับ",
    align: "start",
    sortable: false,
    value: "no"
  },
  { text: "ชื่อโครงการ", value: "agencyProjectName" },
  { text: "จำนวนผู้ดูแล", value: "numOfAdmin" },
  { text: "จำนวนคูปอง", value: "numOfcoupon" },
  { text: "", value: "", componentName: "see-detail-donate" }
];
