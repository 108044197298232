<template>
  <v-card
    class="i-card"
    :style="{
      background: '#fff',
      padding: '14px',
      borderRadius: '6px',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '0px 0px 2px rgba(51, 51, 51, 0.25)'
    }"
    :outlined="outlined"
  >
    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    outlined: {
      default: () => false
    }
  }
};
</script>

<style>
.i-card.v-card.v-sheet--outlined {
  border: thin solid rgba(51, 51, 51, 0.25) !important;
}
</style>
