<template>
  <div>
    <i-header title="รายละเอียดแต้ม" />

    <i-card>
      <div class="filter-container">
        <div class="filter-type">
          <div class="home-title">ประเภท</div>
          <i-select
            v-model="statusSelected"
            label="รับแต้ม-โอนแต้ม"
            item-value="key"
            :items="typeItems"
            hide-details
          ></i-select>
        </div>
        <div class="filter-date">
          <div class="home-title">ช่วงเวลา</div>
          <i-date-range-history
            v-model="dateRange"
            label="ช่วงเวลา"
            hide-details
          ></i-date-range-history>
        </div>
        <div class="filter-action">
          <i-button @click="handleClick">
            ค้นหา
          </i-button>
        </div>
      </div>
    </i-card>
    <i-header title="รายละเอียดแต้ม">
      <div class="action-container">
        <v-btn
          outlined
          color="#1E4D94"
          style="text-transform: none"
          :href="
            `${urlExport}?agencyId=${this.$route.params.id}&type=${statusSelected}&startDate=${this.startDate}&endDate=${this.endDate}`
          "
          >Export file</v-btn
        >
      </div>
    </i-header>

    <Datatables
      :columns="columns"
      url="/donate/getdonate"
      :isPostUrl="true"
      :bodyData="{
        agencyId: this.$route.params.id,
        type: statusSelected,
        startDate: this.startDate,
        endDate: this.endDate
      }"
    />
  </div>
</template>

<script>
import Datatables from "components/datatables/index.vue";
import { columns } from "data/tables/report-detail-columns";

export default {
  name: "project",
  components: {
    Datatables
  },
  data: () => ({
    columns,
    dateRange: [],
    typeItems: [
      {
        key: "all",
        text: "รับแต้ม-โอนแต้ม"
      },
      {
        key: "in",
        text: "รับแต้ม"
      },
      {
        key: "out",
        text: "โอนแต้ม"
      }
    ],
    statusSelected: "all",
    startDate: null,
    endDate: null,
    urlExport: `${process.env.VUE_APP_BASE_URL_API}/donate/exportdonatehistory`
  }),
  methods: {
    handleClick() {
      this.$eventBus.$emit("reload-datatable");
    }
  },
  watch: {
    dateRange: {
      handler() {
        if (this.dateRange.length == 2 && this.dateRange[0] > this.dateRange[1]) {
          let tmp = this.dateRange[0];
          this.dateRange[0] = this.dateRange[1];
          this.dateRange[1] = tmp;
        }
        this.startDate = this.dateRange[0] ? this.dateRange[0] : null;
        this.endDate = this.dateRange[1] ? this.dateRange[1] : null;
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.filter-container {
  display: flex;
  margin: 12px 0;
}
.filter-type {
  flex: 2;
  padding-right: 10px;
}
.filter-date {
  flex: 2;
  padding-left: 10px;
}
.filter-action {
  flex: 1;
  display: flex;
  margin-left: 10px;
  align-items: flex-end;
}
</style>
